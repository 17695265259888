import { Typography } from "@mui/material";
import {
  GridColumnHeaderParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { MinimumAccount } from "./OrganizationAccountTable";
import { MinimumUser } from "./OrganizationUserTable";

export function renderHeader<
  T extends GridValidRowModel = MinimumAccount | MinimumUser,
>(params: GridColumnHeaderParams<T>) {
  return <Typography fontWeight={600}>{params.colDef.headerName}</Typography>;
}
