import { Card, Stack, Typography } from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";
import { useMemo, useRef, useState } from "react";
import { Log, Maybe, PipelineType } from "../../../graphql/generated";
import { SnapshotRegion } from "../regions";
import { flattenFields, getTimestamp } from "../utils";
import { DetailsContainer } from "./DetailsContainer";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { AttributesProvider } from "./SnapShotRow";
import { SummaryTable } from "./SummaryTable";

import { useSnapshot } from "../SnapshotContext";
import styles from "../snap-shot-console.module.scss";
import { FieldType } from "../types";
import { FieldRow } from "./FieldRow";
import { highlightSearchQuery, useWatchForOpen } from "./utils";

interface LogRecordRowProps {
  filtered?: boolean;
  message: Log;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const LogRecordRow: React.FC<LogRecordRowProps> = ({
  filtered,
  message,
  attributes,
  bindplaneID,
}) => {
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Logs),
    [message],
  );

  const severityLabel = useMemo(
    () => getSeverityLabel(message.severityNumber ?? 0, message.severityText),
    [message],
  );
  const severityColor = useMemo(
    () => getSeverityColor(message.severityNumber ?? 0, message.severityText),
    [message],
  );

  const { searchRegex } = useSnapshot();
  // formatting the body as JSON is a bit arbitrary. A structured log doesn't necessarily
  // originate as JSON, but there is no representation of the original format available.
  const body = highlightSearchQuery(
    JSON.stringify(message.body, undefined, 2),
    searchRegex,
  );

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useWatchForOpen(ref, setOpen);

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
      ref={ref}
    >
      <RowSummary
        filtered={filtered}
        bindplaneID={bindplaneID}
        timestamp={timestamp}
        data-region={SnapshotRegion.ROW_SUMMARY}
      >
        <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
          <Chip
            label={severityLabel}
            size={"small"}
            color={severityColor}
            data-region={SnapshotRegion.SEVERITY}
            data-severity={severityLabel}
            className={styles.severity}
          />

          <Typography
            fontFamily="monospace"
            fontSize={12}
            overflow={"hidden"}
            textOverflow="ellipsis"
          >
            {body}
          </Typography>
        </Stack>
      </RowSummary>

      {open && (
        <DetailsContainer filtered={filtered}>
          <Typography fontWeight={600}>Log</Typography>

          <SummaryTable>
            <FieldRow name="time" value={timestamp} fieldType={FieldType.Log} />
            <FieldRow
              name="observed_time"
              value={message.observedTimestamp}
              fieldType={FieldType.Log}
            />
            {(message.severityNumber ?? 0) > 0 && (
              <FieldRow
                name="severity_number"
                value={message.severityNumber}
                fieldType={FieldType.Log}
              />
            )}
            {message.severityText !== "" && (
              <FieldRow
                name="severity_text"
                value={message.severityText}
                fieldType={FieldType.Log}
              />
            )}
            {message.spanID !== "" && (
              <FieldRow
                name="span_id.string"
                value={message.spanID}
                fieldType={FieldType.Log}
              />
            )}
            {message.traceID !== "" && (
              <FieldRow
                name="trace_id.string"
                value={message.traceID}
                fieldType={FieldType.Log}
              />
            )}
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Body
          </Typography>
          <MapValueSummary
            value={flattenFields(message.body)}
            fieldType={FieldType.Body}
            emptyMessage="No body values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Attributes
          </Typography>
          <MapValueSummary
            value={flattenFields(attributes())}
            fieldType={FieldType.Attribute}
            emptyMessage="No attribute values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Resource
          </Typography>
          <MapValueSummary
            value={flattenFields(message.resource)}
            fieldType={FieldType.Resource}
            emptyMessage="No resource values"
          />
        </DetailsContainer>
      )}
    </Card>
  );
};

type severityOption =
  | "default"
  | "trace"
  | "debug"
  | "info"
  | "warning"
  | "error"
  | "fatal";

const severityOptions = [
  "default",
  "trace",
  "debug",
  "info",
  "warning",
  "error",
  "fatal",
];

const severityMap: { [num: number]: severityOption } = {
  1: "trace",
  2: "trace",
  3: "trace",
  4: "trace",
  5: "debug",
  6: "debug",
  7: "debug",
  8: "debug",
  9: "info",
  10: "info",
  11: "info",
  12: "info",
  13: "warning",
  14: "warning",
  15: "warning",
  16: "warning",
  17: "error",
  18: "error",
  19: "error",
  20: "error",
  21: "fatal",
  22: "fatal",
  23: "fatal",
  24: "fatal",
};

function getSeverityLabel(
  severityNumber: number,
  severityText: Maybe<string> | undefined,
): string {
  if (severityText) {
    return severityText;
  }
  return severityMap[severityNumber] ?? "default";
}

function getSeverityColor(
  severityNumber: number,
  severityText: Maybe<string> | undefined,
): ChipProps["color"] {
  const severityColor = severityMap[severityNumber];
  if (severityColor) {
    return severityColor;
  }
  if (severityOptions.includes(severityText ?? "")) {
    return severityText as severityOption;
  }
  return "default";
}
