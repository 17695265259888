import { Box, BoxProps, colors } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styles from "./resize-handle.module.scss";

export interface ResizeHandleProps extends BoxProps {
  resizeCallback: (delta: number) => void;
  horizontal?: boolean;
}

export const ResizeHandle: React.FC<ResizeHandleProps> = ({
  resizeCallback,
  horizontal = false,
}) => {
  const [dragging, setDragging] = useState(false);
  const lastPosition = useRef(-1);
  const resizeCallbackRef = useRef(resizeCallback);

  useEffect(() => {
    // Update the ref to the latest callback on each render,
    // ensuring the latest callback is used without needing it as a dependency
    resizeCallbackRef.current = resizeCallback;
  });

  useEffect(() => {
    const onDrag = (e: MouseEvent) => {
      if (!dragging) return;

      var currentPosition = e.clientX;
      if (horizontal) {
        currentPosition = e.clientY;
      }
      const delta = currentPosition - lastPosition.current;
      if (lastPosition.current !== -1) {
        resizeCallbackRef.current(delta);
      }
      lastPosition.current = currentPosition;
    };

    const stopDrag = () => {
      setDragging(false);
      lastPosition.current = -1; // Reset lastX when dragging stops
    };

    if (dragging) {
      window.addEventListener("mousemove", onDrag);
      window.addEventListener("mouseup", stopDrag);
      if (horizontal) {
        document.body.style.cursor = "row-resize";
      } else {
        document.body.style.cursor = "col-resize";
      }
    }

    return () => {
      window.removeEventListener("mousemove", onDrag);
      window.removeEventListener("mouseup", stopDrag);
      document.body.style.cursor = "";
    };
  }, [dragging, horizontal]); // Only re-run this effect if `dragging` changes

  const startDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (horizontal) {
      lastPosition.current = e.clientY; // Set initial Y position
    } else {
      lastPosition.current = e.clientX; // Set initial X position
    }
    setDragging(true);
  };
  return (
    <Box
      className={styles[horizontal ? "handle-horizontal" : "handle"]}
      minWidth={horizontal ? "100%" : "4px"}
      minHeight={horizontal ? "4px" : "100%"}
      borderColor={"#000"}
      borderRadius={1}
      bgcolor={dragging ? "primary.main" : colors.grey[300]}
      onMouseDown={startDrag}
    />
  );
};
