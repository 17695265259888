import { gql } from "@apollo/client";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  GetAccountPageInfoQuery,
  Role,
  useGetInvitationsQuery,
  useInvitationsEnabledQuery,
  useInvitationsSubscription,
  useUpdateProjectNameMutation,
} from "../../../graphql/generated";
import { BusinessIcon, PlusCircleIcon } from "../../../components/Icons";
import { InvitationsTable } from "../../../components/InvitationsTable";
import { InviteUserDialog } from "../../../components/InviteUserDialog";
import { RBACWrapper } from "../../../contexts/RBAC";
import { getAuthType } from "../../../utils/get-auth-type";
import { AuthType } from "../../../types/auth";
import { trim } from "lodash";
import { LinkButton } from "../../../components/LinkButton";

import styles from "./account-page.module.scss";

gql`
  query getInvitations {
    invitations {
      id
      accountId
      email
      createdAt
      expiredAt
      redeemedAt
      createdBy
      redeemedBy
      role
      note
      status
      link
    }
  }

  query invitationsEnabled {
    featureGate(id: "multiuser")
  }

  subscription invitations {
    invitations {
      id
    }
  }

  mutation updateProjectName($input: RenameAccountInput!) {
    renameAccount(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
    }
  }
`;
interface AccountTabProps {
  account: NonNullable<GetAccountPageInfoQuery["accounts"][0]>;
  refetch: () => void;
}

export const AccountTab: React.FC<AccountTabProps> = ({ account }) => {
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [invitationsEnabled, setInvitationsEnabled] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [projectNameValue, setProjectNameValue] = useState(
    account?.metadata.displayName,
  );
  const [projectNameError, setProjectNameError] = useState<string | null>(null);

  const [updateProjectName] = useUpdateProjectNameMutation();

  const { data, loading, refetch } = useGetInvitationsQuery({
    fetchPolicy: "network-only",
  });

  useInvitationsSubscription({
    onData: () => {
      refetch();
    },
  });

  useInvitationsEnabledQuery({
    onCompleted(data) {
      setInvitationsEnabled(data.featureGate);
    },
  });

  function handleAddUserClick() {
    setAddUserOpen(true);
  }

  function handleCloseAddUserDialog() {
    setAddUserOpen(false);
  }

  function handleNameFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setProjectNameValue(e.target.value);
  }

  function handleNameFieldClickAway() {
    handleSaveProjectName();
  }

  function handleNameFieldKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleSaveProjectName();
    }

    if (e.key === "Escape") {
      setEditingName(false);
      setProjectNameValue(account.metadata.displayName);
    }
  }

  async function handleSaveProjectName() {
    const error = validateProjectName(projectNameValue);
    if (error !== null) {
      setProjectNameError(error);
      return;
    }

    await updateProjectName({
      variables: { input: { name: projectNameValue! } },
      onCompleted: () => {
        refetch();
        setEditingName(false);
      },
      onError: (error) => {
        console.error(error);
        setProjectNameError("Failed to update project");
      },
    });
  }

  function handleEditClick() {
    setEditingName(true);
  }

  return (
    <>
      <Typography className={styles.header} marginBottom={2}>
        Project Information
      </Typography>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Avatar classes={{ root: styles.avatar }}>
              <BusinessIcon />
            </Avatar>

            <Stack direction="row">
              <Typography fontWeight={600} className={styles.key}>
                Name:
              </Typography>
              {editingName ? (
                <ClickAwayListener onClickAway={handleNameFieldClickAway}>
                  <TextField
                    variant="standard"
                    onKeyDown={handleNameFieldKeyDown}
                    className={styles.nameField}
                    value={projectNameValue}
                    onChange={handleNameFieldChange}
                    error={!!projectNameError}
                    helperText={projectNameError}
                    autoFocus
                  />
                </ClickAwayListener>
              ) : (
                <>
                  <Typography>{account.metadata.displayName}</Typography>
                  <Box marginLeft={1}>
                    <LinkButton onClick={handleEditClick}>edit</LinkButton>
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {invitationsEnabled && getAuthType() !== AuthType.Basic && (
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginY={1}
          >
            <Typography className={styles.header} marginY={2}>
              Invitations
            </Typography>

            <RBACWrapper requiredRole={Role.Admin}>
              <Button
                variant="contained"
                startIcon={<PlusCircleIcon />}
                onClick={handleAddUserClick}
              >
                Invite Users
              </Button>
            </RBACWrapper>
          </Stack>

          <InvitationsTable data={data} loading={loading} />

          <InviteUserDialog
            open={addUserOpen}
            onClose={handleCloseAddUserDialog}
            onCancel={handleCloseAddUserDialog}
          />
        </>
      )}
    </>
  );
};

export function validateProjectName(
  name: string | undefined | null,
): string | null {
  if (name == null || trim(name) === "") {
    return "Project name cannot be empty";
  }

  if (name.length > 64) {
    return "Project name must be 64 characters or less";
  }
  return null;
}
