import { gql } from "@apollo/client";
import { Stack, Switch } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { CheckIcon } from "../../../components/Icons";
import { Maybe, useToggleMfaMutation } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { AuthType } from "../../../types/auth";
import { getAuthType } from "../../../utils/get-auth-type";
import { UserOptionsCell } from "./UserOptionsCell";
import { renderHeader } from "./utils";

gql`
  mutation ToggleMFA($input: ToggleMFAInput!) {
    toggleMFA(input: $input)
  }
`;

export type MinimumUser = {
  metadata: {
    id: string;
    displayName?: Maybe<string>;
  };
  spec: {
    requestedMFA?: Maybe<boolean>;
  };
  isOrgAdmin: boolean;
};

interface OrganizationUserTableProps {
  currentUserId: string;
  users: MinimumUser[];
  refetch: () => void;
}

export const OrganizationUserTable: React.FC<OrganizationUserTableProps> = ({
  currentUserId,
  users,
  refetch,
}) => {
  const [toggleMFA] = useToggleMfaMutation();
  const [mfaRequested, setMfaRequested] = useState<boolean>(
    users.find((user) => user.metadata.id === currentUserId)?.spec
      .requestedMFA ?? false,
  );

  const { enqueueSnackbar } = useSnackbar();

  const authType = getAuthType();

  async function handleToggleMFA(userID: string) {
    if (userID !== currentUserId) {
      console.error("User is not allowed to toggle MFA for other users");
      return;
    }
    const enroll = !mfaRequested;
    try {
      const { errors: errs } = await toggleMFA({
        variables: {
          input: {
            userId: currentUserId ?? "",
            enroll,
          },
        },
      });
      if (errs) {
        console.error(errs);
        enqueueSnackbar("Failed to toggle MFA", {
          variant: "error",
          key: "toggle-mfa-error",
        });
        return;
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to toggle MFA", {
        variant: "error",
        key: "toggle-mfa-error",
      });
      return;
    }

    if (enroll) {
      enqueueSnackbar(
        "MFA enrollment initiated, please check your email for instructions",
        {
          variant: "success",
        },
      );
    } else {
      enqueueSnackbar("MFA has been disabled", {
        variant: "success",
      });
    }

    setMfaRequested(!mfaRequested);
  }

  const columns: GridColDef<MinimumUser>[] = [
    {
      field: "displayName",
      headerName: "Name",
      valueGetter: (_, row) => row.metadata.displayName,
      renderHeader,
      flex: 1,
    },
    {
      field: "orgAdmin",
      headerName: "Organization Admin",
      renderHeader,
      valueGetter: (_, row) => row.isOrgAdmin,
      renderCell: (params) => (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          {params.row.isOrgAdmin ? (
            <CheckIcon
              stroke={colors.pixelPointBlue}
              data-testid={`${params.row.metadata.id}-admin-checkmark`}
            />
          ) : null}
        </Stack>
      ),
      width: 250,
    },
    {
      field: " ",
      headerName: "",
      width: 50,
      renderCell: (params) =>
        params.row.metadata.id === currentUserId ? null : (
          <UserOptionsCell
            isOrgAdmin={params.row.isOrgAdmin}
            id={params.row.metadata.id}
            refetch={refetch}
          />
        ),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  if (authType === AuthType.Auth0) {
    const userOptionsCell = columns.pop();

    columns.push({
      field: "requestedMFA",
      headerName: "MFA Enabled",
      renderHeader,
      valueGetter: (_, row) => row.spec.requestedMFA ?? false,
      renderCell: (params) => (
        <Stack
          width="60%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Switch
            checked={
              params.row.metadata.id === currentUserId
                ? mfaRequested
                : (params.row.spec.requestedMFA ?? false)
            }
            disabled={
              params.row.metadata.id == null ||
              params.row.metadata.id !== currentUserId
            }
            onClick={() => handleToggleMFA(params.row.metadata.id)}
            data-testid={`mfa-toggle-${params.row.metadata.id}`}
          />
        </Stack>
      ),
      width: 250,
    });
    columns.push(userOptionsCell!);
  }

  return (
    <DataGridPro
      rows={users}
      columns={columns}
      getRowId={(row: MinimumUser) => row.metadata.id}
      disableRowSelectionOnClick
      sortingOrder={["asc", "desc"]}
      density="compact"
      initialState={{
        sorting: {
          sortModel: [{ field: "displayName", sort: "asc" }],
        },
      }}
      slots={{ footer: () => null }}
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      }}
      disableVirtualization={process.env.NODE_ENV === "test"}
    />
  );
};
