import { trim } from "lodash";
import {
  VerifyOrganizationPayload,
  VerifyOrganizationResponse,
} from "../../types/rest";

export async function verifyOrganization(details: VerifyOrganizationPayload) {
  return await fetch("/v1/organizations/verify", {
    method: "PUT",
    body: JSON.stringify(details),
  });
}

/**
 * validateOrganizationName returns an error message if the organization name is invalid.
 * Otherwise, it returns null.
 *
 * @param orgName the name of the organization you want to test
 * @returns
 */
export async function validateOrganizationName(
  orgName: string,
): Promise<string | null> {
  const sanitized = trim(orgName);
  const inputError = validateOrgInput(sanitized);
  if (inputError) {
    return inputError;
  }

  const resp = await verifyOrganization({
    name: sanitized,
  });
  if (!resp.ok) {
    return null;
  }

  const respJson = (await resp.json()) as VerifyOrganizationResponse;
  if (respJson.valid) {
    return null;
  }

  return "Organization name is already taken";
}

function validateOrgInput(orgName: string): string | null {
  if (orgName === "") {
    return "Organization name is required";
  }

  if (orgName.length > 64) {
    return "Organization name must be less than 64 characters";
  }

  return null;
}
