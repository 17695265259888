import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  RolloutStage,
  useAgentLabeledForConfigurationSubscription,
  useGetConfigRolloutStatusQuery,
  useGetRolloutStageSizesQuery,
} from "../../graphql/generated";
import { useRefetchOnConfigurationStatusChange } from "../../hooks/useRefetchOnConfigurationChanges";
import {
  pauseRollout,
  resumeRollout,
  startRollout,
} from "../../utils/rest/rollouts-rest-fns";
import { nameAndVersion } from "../../utils/version-helpers";
import { EditingState } from "../EditingControlBar/EditingControlBar";
import { RolloutProgressBar } from "../RolloutProgressBar";
import { RolloutProgressiveProgressBar } from "../RolloutProgressiveProgressBar";
import { RolloutProgressData } from "./rollout-progress-data";
import { LiveOrDraft } from "../../pages/configurations/configuration";

gql`
  query getConfigRolloutStatus($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
        dateModified
      }
      agentCount
      status {
        pending
        current
        latest
        rollout {
          status
          phase
          completed
          errors
          pending
          waiting
          stages {
            name
            labels
            progress {
              completed
              errors
              pending
              waiting
            }
          }
          stage
        }
      }
      spec {
        rollout {
          id
          name
          type
        }
      }
    }

    configurationUnlicensedComponentsInfo(name: $name) {
      usingUnlicensedComponents
    }
  }
  query GetRolloutStageSizes($configName: String!) {
    agentsForPendingProgressiveRollout(configName: $configName) {
      agents
      name
    }
  }

  subscription agentLabeledForConfiguration($name: String!) {
    agentLabeledForConfigurationChanges(name: $name)
  }
`;

interface RolloutProgressProps {
  configurationName: string;
  configurationVersion: string;
  hideActions?: boolean;
  setShowCompareVersions: (show: boolean) => void;
  setEditingState: (state: EditingState) => void;
  editingState: EditingState;
  view?: LiveOrDraft;
}

/**
 * RolloutProgress wraps the RolloutProgressBar component with a query
 * and subscription for the data.
 * The progress bar is only shown if agents are in the rollout or it's not version 1.
 *
 * @param configurationName The name of the configuration, should not contain a version
 * @param configurationVersion The version of the configuration, should be a string "latest" or "pending"
 * @param showCompleted Whether to show the progress bar when the rollout is completed
 * @param hideActions Whether to hide the pause/resume/start buttons
 * @param setShowCompareVersions Whether to show compare versions
 * @param setEditingState Used to synchronize with EditingControlBar
 * @param editingState Used to synchronize with EditingControlBar
 * @param view Used to synchronize with Live/Draft switcher and EditingControlBar
 */
export const RolloutProgress: React.FC<RolloutProgressProps> = ({
  configurationName,
  configurationVersion,
  hideActions,
  setShowCompareVersions,
  setEditingState,
  editingState,
  view,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [progressData, setProgressData] = useState<RolloutProgressData>();
  const [disableStartRollout, setDisableStartRollout] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [rolloutType, setRolloutType] = useState<string>();
  const [rolloutStages, setRolloutStages] = useState<RolloutStage[] | null>();
  const [rolloutStage, setRolloutStage] = useState<number | null>();

  const versionedName = nameAndVersion(configurationName, configurationVersion);

  const { refetch } = useGetConfigRolloutStatusQuery({
    variables: { name: versionedName },
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.configuration) {
        const newData = new RolloutProgressData(data.configuration);
        setProgressData(newData);
        setLoading(false);
        setRolloutType(data.configuration.spec?.rollout?.type || "standard");
        setRolloutStages(data.configuration.status.rollout.stages);
        setRolloutStage(data.configuration.status.rollout.stage);
        setDisableStartRollout(
          data.configurationUnlicensedComponentsInfo.usingUnlicensedComponents,
        );
      }
    },
    pollInterval: 5000,
  });

  useRefetchOnConfigurationStatusChange(configurationName, refetch);

  useAgentLabeledForConfigurationSubscription({
    variables: {
      name: configurationName,
    },
    onData() {
      refetch();
    },
    onError(error) {
      console.error(`useAgentLabeledForConfiguration error: ${error}`);
    },
  });

  // Hide the progress bar after a timeout if the rollout is completed
  useEffect(() => {
    if (progressData == null) {
      return;
    }

    if (
      progressData.rolloutIsStarted() ||
      progressData.rolloutIsPaused() ||
      progressData.rolloutIsErrored()
    ) {
      setEditingState("draft");
      return;
    }

    if (editingState !== "discarding" && editingState !== "justCompleted") {
      // Show for non completed rollouts
      if (!progressData.rolloutIsComplete()) {
        if (view === "live") {
          return;
        }
        if (view === "draft") {
          setEditingState("draft");
          return;
        }
      }

      //Hide if rollout completed over 4 seconds ago
      if (progressData.isPastCompletion()) {
        setEditingState("pastCompleted");
        return;
      }

      // Rollout completed within last 4 seconds
      if (editingState !== "pastCompleted") {
        setEditingState("justCompleted");
      }
    }
  }, [setEditingState, editingState, view, progressData]);

  /**
   * handleStartRollout is passed to the BuildRolloutDialog and starts the rollout with default options.
   */
  async function handleStartRollout() {
    setLoading(true);
    try {
      await startRollout(versionedName);
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to start rollout", {
        variant: "error",
        key: "start-failed",
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * handlePauseRollout is called when the user clicks the "Pause Rollout" button.
   */
  async function handlePauseRollout() {
    setLoading(true);
    try {
      await pauseRollout(versionedName);
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to pause rollout", {
        variant: "error",
        key: "pause-failed",
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * handleResumeRollout is called when the user clicks the "Resume Rollout" button.
   */
  async function handleResumeRollout() {
    setLoading(true);
    try {
      await resumeRollout(versionedName);
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to resume rollout", {
        variant: "error",
        key: "resume-failed",
      });
    } finally {
      setLoading(false);
    }
  }

  // If this is an unstarted progressive rollout, get the stage sizes
  useGetRolloutStageSizesQuery({
    variables: {
      configName: configurationName,
    },
    onCompleted(data) {
      const res = data.agentsForPendingProgressiveRollout;
      if (res) {
        setRolloutStages(
          res.map((r) => ({
            progress: {
              completed: 0,
              errors: 0,
              pending: 0,
              waiting: r.agents,
            },
            name: r.name,
            labels: [],
          })),
        );
      }
    },
    skip:
      (rolloutStages != null && rolloutStage != null) ||
      rolloutType !== "progressive",
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (progressData?.configuration.metadata.version === 1) {
      setShowCompareVersions(false);
      return;
    }
    if (progressData?.rolloutStatus() === 4) {
      setShowCompareVersions(false);
      return;
    }
    setShowCompareVersions(true);
  }, [progressData, setShowCompareVersions]);

  if (progressData == null || rolloutType == null) {
    // TODO(dsvanlani): Show a loading indicator
    return null;
  }

  if (rolloutType === "progressive") {
    if (rolloutStages == null || rolloutStage == null) {
      return null;
    } else {
      return (
        <RolloutProgressiveProgressBar
          configurationName={configurationName}
          totalCount={progressData.agentCount()}
          errors={progressData.errored()}
          completedCount={progressData.completed()}
          rolloutStatus={progressData.rolloutStatus()}
          hideActions={hideActions}
          paused={!progressData.rolloutIsStarted()}
          loading={loading}
          onPause={handlePauseRollout}
          onStart={handleStartRollout}
          onResume={handleResumeRollout}
          editingState={editingState}
          rolloutStages={rolloutStages}
          rolloutStage={rolloutStage}
        />
      );
    }
  }

  return (
    <RolloutProgressBar
      configurationName={configurationName}
      totalCount={progressData.agentCount()}
      errors={progressData.errored()}
      completedCount={progressData.completed()}
      rolloutStatus={progressData.rolloutStatus()}
      hideActions={hideActions}
      paused={!progressData.rolloutIsStarted()}
      loading={loading}
      onPause={handlePauseRollout}
      onStart={handleStartRollout}
      onResume={handleResumeRollout}
      editingState={editingState}
      disableStartRollout={disableStartRollout}
    />
  );
};
