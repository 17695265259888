import { Stability } from "../../types/resources";
import { StabilityChip } from "./StabilityChip";

export function getStabilityChip(
  stability?: string,
  style?: React.CSSProperties,
): React.ReactElement | null {
  switch (stability) {
    case Stability.DEPRECATED:
      return <StabilityChip color="warning" label="Deprecated" style={style} />;
    case Stability.DEVELOPMENT:
      return (
        <StabilityChip color="default" label="Development" style={style} />
      );
    case Stability.ALPHA:
      return <StabilityChip color="default" label="Alpha" style={style} />;
    case Stability.BETA:
      return <StabilityChip color="default" label="Beta" style={style} />;
    case Stability.STABLE:
    default:
      return null;
  }
}
