import { Alert } from "@mui/lab";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { isFunction } from "lodash";
import { AdditionalInfo } from "../../graphql/generated";
import colors from "../../styles/colors";
import { BookmarkIcon, HelpCircleIcon } from "../Icons";
import { getStabilityChip } from "../StabilityChip";

interface ViewHeadingProps {
  heading?: string;
  subHeading?: string;
  additionalInfo?: AdditionalInfo | null;
  stability?: string;
  resourceDocLink?: string;
  showLibraryBookmark?: boolean;
  readOnly?: boolean;
  resourceInLibrary?: boolean;
  setAddToLibraryDialogOpen?: (b: boolean) => void;
  setUnlinkFromLibraryDialogOpen?: (b: boolean) => void;
}

export const ViewHeading: React.FC<ViewHeadingProps> = ({
  heading,
  subHeading,
  additionalInfo,
  stability,
  resourceDocLink,
  showLibraryBookmark,
  readOnly,
  resourceInLibrary,
  setAddToLibraryDialogOpen,
  setUnlinkFromLibraryDialogOpen,
}) => {
  function handleBookmarkClick() {
    if (resourceInLibrary) {
      isFunction(setUnlinkFromLibraryDialogOpen)
        ? setUnlinkFromLibraryDialogOpen(true)
        : console.error(
            "No function provided for setUnlinkFromLibraryDialogOpen",
          );
    } else {
      isFunction(setAddToLibraryDialogOpen)
        ? setAddToLibraryDialogOpen(true)
        : console.error("No function provided for setAddToLibraryDialogOpen");
    }
  }

  let tooltipMessage: string;
  if (readOnly) {
    if (resourceInLibrary) {
      tooltipMessage = "Resource is in Library";
    } else {
      tooltipMessage = "Resource is not in Library";
    }
  } else {
    if (resourceInLibrary) {
      tooltipMessage = "Unlink resource from Library";
    } else {
      tooltipMessage = "Add resource to Library";
    }
  }

  return (
    <Stack paddingBottom={1}>
      {heading && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip title={heading}>
            <Typography
              fontWeight={600}
              fontSize={24}
              textOverflow={"ellipsis"}
              overflow="hidden"
              maxWidth={"80%"}
            >
              {heading}
            </Typography>
          </Tooltip>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            flexGrow={1}
          >
            <Stack direction={"row"}>
              {resourceDocLink && (
                <IconButton
                  href={resourceDocLink}
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                >
                  <HelpCircleIcon />
                </IconButton>
              )}
              {getStabilityChip(stability, {
                float: "right",
                margin: "auto",
                marginLeft: 8,
              })}
            </Stack>
            {showLibraryBookmark && (
              <Tooltip title={tooltipMessage}>
                {/* span around button allows Tooltip to show when button is disabled, otherwise there are console errors */}
                <span>
                  <IconButton
                    color={resourceInLibrary ? "primary" : undefined}
                    onClick={handleBookmarkClick}
                    disabled={readOnly}
                    data-testid="processor-configuration-bookmark-icon"
                  >
                    {resourceInLibrary && readOnly ? (
                      <BookmarkIcon
                        fill={colors.pixelPointBlue}
                        stroke={colors.pixelPointBlue}
                      />
                    ) : (
                      <BookmarkIcon
                        fill={
                          resourceInLibrary ? colors.pixelPointBlue : "none"
                        }
                      />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
      {subHeading && <Typography fontSize={16}>{subHeading}</Typography>}
      {additionalInfo && (
        <Alert severity="info" data-testid="info-alert">
          <Typography>
            {additionalInfo.message}
            {additionalInfo.documentation?.map((d) => (
              <div key={d.url}>
                <a href={d.url} rel="noreferrer" target="_blank">
                  {d.text}
                </a>
              </div>
            ))}
          </Typography>
        </Alert>
      )}
    </Stack>
  );
};
