import { gql } from "@apollo/client";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useGetConfigurationExtensionsQuery } from "../../graphql/generated";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { AdvancedConfigDialog } from "../../pages/configurations/configuration/AdvancedConfigDialog";
import { nameAndVersion } from "../../utils/version-helpers";
import { CountChip } from "../CountChip";
import { ExtensionsDialog } from "../ExtensionsDialog";
import { SettingsIcon } from "../Icons";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";

import styles from "./config-details-menu.module.scss";

gql`
  query GetConfigurationExtensions($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
      }
      spec {
        extensions {
          name
          id
          type
        }
      }
    }
  }
`;

interface ConfigDetailsMenuProps {
  configName: string;
  menuButtonClassname?: string;
  iconProps?: React.ComponentProps<typeof SettingsIcon>;
  readOnly?: boolean;
}

export const ConfigDetailsMenu: React.FC<ConfigDetailsMenuProps> = ({
  configName,
  iconProps,
  menuButtonClassname,
  readOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false);
  const [extensionsDialogOpen, setExtensionsDialogOpen] = useState(false);
  const [extensionsCount, setExtensionsCount] = useState<undefined | number>();

  const { configuration } = usePipelineGraph();
  const version = configuration?.metadata?.version;

  const versionedName = nameAndVersion(configName, version);
  const { refetch } = useGetConfigurationExtensionsQuery({
    variables: {
      name: versionedName,
    },
    skip: version == null,
    fetchPolicy: "network-only",
    onCompleted(data) {
      setExtensionsCount(data.configuration?.spec.extensions?.length);
    },
  });

  useRefetchOnConfigurationChange(configName, refetch);

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAdvancedDialogClick() {
    setAdvancedDialogOpen(true);
    setAnchorEl(null);
  }

  function handleExtensionsDialogClick() {
    setExtensionsDialogOpen(true);
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        data-testid="config-menu-button"
        id="config-menu-button"
        aria-controls={menuOpen ? "config-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
        className={menuButtonClassname}
      >
        <SettingsIcon {...iconProps} />
      </IconButton>
      <Menu
        id="config-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "config-menu-button",
        }}
        slotProps={{
          paper: {
            className: styles.paper,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid="config-details-menu"
      >
        <MenuItem onClick={handleAdvancedDialogClick}>
          <ListItemText>Advanced Configuration</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleExtensionsDialogClick}>
          <ListItemText>Manage Extensions</ListItemText>
          <ListItemText className={styles.listItemChip}>
            {extensionsCount != null && <CountChip count={extensionsCount} />}
          </ListItemText>
        </MenuItem>
      </Menu>

      <AdvancedConfigDialog
        open={advancedDialogOpen}
        onClose={() => {
          setAdvancedDialogOpen(false);
          setAnchorEl(null);
        }}
        onSuccess={() => {
          setAdvancedDialogOpen(false);
          setAnchorEl(null);
        }}
        readOnly={readOnly}
      />

      <ExtensionsDialog
        open={extensionsDialogOpen}
        closeDialog={() => setExtensionsDialogOpen(false)}
      />
    </>
  );
};
