import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { ReactElement } from "react";
import styles from "./measurement-control-bar.module.scss";

export const AGENT_SCRAPE_PERIODS: { [period: string]: string } = {
  "10s": "10s",
  "1m": "1m",
  "15m": "15m",
};

export const QUERY_PERIODS: { [period: string]: string } = {
  "1h": "1h",
  "24h": "24h",
};
export const DEFAULT_QUERY_PERIOD = "1m";
export const DEFAULT_AGENTS_TABLE_QUERY_PERIOD = "1h";
export const DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD = "1h";
export const DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD = "24h";
export const DEFAULT_DESTINATIONS_TABLE_QUERY_PERIOD = "1h";

export const TELEMETRY_TYPES: { [telemetryType: string]: string } = {
  logs: "Logs",
  metrics: "Metrics",
  traces: "Traces",
};
export const DEFAULT_TELEMETRY_TYPE = "logs";

export const TELEMETRY_SIZE_METRICS: { [telemetryType: string]: string } = {
  logs: "log_data_size",
  metrics: "metric_data_size",
  traces: "trace_data_size",
};

interface MeasurementControlBarProps {
  telemetry: string;
  onTelemetryTypeChange: (telemetry: string) => void;
  period: string;
  onPeriodChange: (period: string) => void;
  periods?: string[];
  children?: ReactElement;
}

/**
 * MeasurementControlBar is a component that allows the user to change the telemetry type and period
 * for the topology graph.
 *
 * @param onTelemetryTypeChange called when the user changes the telemetry type
 * @param onPeriodChange called when the user changes the period
 * @param telemetry the current stateful telemetry type
 * @param period the current stateful period
 * @param children any children to render, intended for Live|Draft tab switcher on configuration topology
 * @returns
 */
export const MeasurementControlBar: React.FC<MeasurementControlBarProps> = ({
  onTelemetryTypeChange,
  onPeriodChange,
  telemetry,
  period,
  periods = Object.keys(QUERY_PERIODS),
  children,
}) => {
  function handleTelemetryChange(
    _event: React.SyntheticEvent<Element, Event>,
    value: any,
  ) {
    if (value === null) {
      return;
    }
    onTelemetryTypeChange(value);
  }

  function handlePeriodChange(
    _event: React.SyntheticEvent<Element, Event>,
    value: any,
  ) {
    if (value === null) {
      return;
    }
    onPeriodChange(value);
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={styles.stack}
    >
      <ToggleButtonGroup
        classes={{ root: styles.toggleGroup }}
        color="primary"
        value={telemetry}
        size="small"
        exclusive
        onChange={handleTelemetryChange}
      >
        {Object.entries(TELEMETRY_TYPES).map(([t, label]) => (
          <ToggleButton
            value={t}
            key={`telemetry-type-${t}`}
            classes={{ root: styles.telemetryButton }}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {children}
      <ToggleButtonGroup
        classes={{ root: styles.toggleGroup }}
        color="primary"
        value={period}
        size="small"
        exclusive
        onChange={handlePeriodChange}
      >
        {periods.map((p) => (
          <ToggleButton
            value={p}
            key={`telemetry-type-${p}`}
            classes={{ root: styles.periodButton }}
          >
            {p}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};
