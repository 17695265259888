import { isArray, isEqual, isObject, sortBy, sortedUniqBy } from "lodash";
import { DataPoint, Log, PipelineType, Span } from "../../graphql/generated";
import { FieldType, dataPointTypeEnumSymbol } from "./types";

const logDateFormat = new Intl.DateTimeFormat(undefined, {
  // month: "short",
  // day: "2-digit",
  // year: undefined,
  hour: "numeric",
  minute: "2-digit",
  second: "2-digit",
  timeZoneName: "short",
});

export type SnapshotItem = Log | DataPoint | Span;

export function formatLogDate(date: Date): string {
  return logDateFormat.format(date);
}

export function getTimestamp(message: SnapshotItem, type: PipelineType): any {
  switch (type) {
    case PipelineType.Logs:
      return (message as Log).timestamp;
    case PipelineType.Metrics:
      return (message as DataPoint).timestamp;
    case PipelineType.Traces:
      return (message as Span).end;
  }
}

// ----------------------------------------------------------------------
// bindplane ids

/**
 * Returns the bindplane id for a message.
 */
export function getBindplaneID(message: SnapshotItem): string {
  return message.attributes?.["__bindplane_id__"] ?? "";
}

export function findBindplaneID(
  messages: SnapshotItem[],
  bindplaneID: string,
): SnapshotItem | undefined {
  return messages.find((message) => getBindplaneID(message) === bindplaneID);
}

// ----------------------------------------------------------------------
// fields

export function hasField(
  message: SnapshotItem | undefined,
  fieldType: FieldType,
  fieldKey: string,
): boolean {
  return getField(message, fieldType, fieldKey) != null;
}

export function hasFieldValue(
  message: SnapshotItem | undefined,
  fieldType: FieldType,
  fieldKey: string,
  fieldValue: any,
): boolean {
  return isEqual(getField(message, fieldType, fieldKey), fieldValue);
}

const logKeys: Record<string, string> = {
  time: "timestamp",
  observed_time: "observedTimestamp",
  severity_text: "severityText",
  severity_number: "severityNumber",
  "trace_id.string": "traceID",
  "span_id.string": "spanID",
};

const datapointKeys: Record<string, string> = {
  description: "description",
  name: "name",
  quantile_values: "quantileValues",
  start_time: "startTimestamp",
  time: "timestamp",
  unit: "unit",
  value_double: "valueDouble",
  value_int: "valueInt",
};

const metricKeys: Record<string, string> = {
  name: "name",
  time: "timestamp",
  type: "type",
  unit: "unit",
  value: "value",
  description: "description",
};

const spanKeys: Record<string, string> = {
  end_time: "end",
  name: "name",
  "parent_span_id.string": "parentSpanID",
  "span_id.string": "spanID",
  start_time: "start",
  "trace_id.string": "traceID",
};

export function getField(
  message: SnapshotItem | undefined,
  fieldType: FieldType,
  fieldKey: string,
): any | undefined {
  if (message == null) {
    return false;
  }
  switch (fieldType) {
    case FieldType.Log:
      return (message as any)[logKeys[fieldKey]];

    case FieldType.DataPoint:
      const datapoint = message as DataPoint;
      const datapointKey = datapointKeys[fieldKey];
      return datapointKey
        ? (datapoint as any)[datapointKey]
        : datapoint.fields?.[fieldKey];

    case FieldType.Metric:
      const metric = message as DataPoint;
      const metricKey = metricKeys[fieldKey];
      const value = metricKey
        ? (metric as any)[metricKey]
        : metric.fields?.[fieldKey];
      if (fieldKey === "type") {
        return dataPointTypeEnumSymbol(value);
      }
      return value;

    case FieldType.Span:
      const span = message as Span;
      const spanKey = spanKeys[fieldKey];
      return spanKey ? (span as any)[spanKey] : span.fields?.[fieldKey];

    case FieldType.Scope:
      return message.scope?.attributes[fieldKey];

    case FieldType.Body:
      const body = (message as Log).body;
      if (isObject(body)) {
        return flattenFields(body)[fieldKey];
      }
      return body;

    case FieldType.Resource:
      return flattenFields(message.resource)[fieldKey];

    case FieldType.Attribute:
      return flattenFields(message.attributes)[fieldKey];

    default:
      return undefined;
  }
}

// returns all of the possible values for a field
export function getFieldValues(
  fieldKey: string,
  fieldType: FieldType,
  items: SnapshotItem[],
): any[] {
  if (fieldKey === "") {
    return [];
  }
  const values = items
    .map((item) => getField(item, fieldType, fieldKey))
    .filter((v) => v != null);
  return sortedUniqBy(
    sortBy(values, (v) => String(v).toLocaleLowerCase()),
    (v) => String(v).toLocaleLowerCase(),
  );
}

// flattenFields takes a nested object and flattens it into a single level, using OTTL
// syntax for nested fields.
export function flattenFields(fields: any, prefix = ""): Record<string, any> {
  const flattened: Record<string, any> = {};

  // if it's not an object, just return it as is
  if (isArray(fields)) {
    // use [i] syntax for each element
    for (let i = 0; i < fields.length; i++) {
      Object.assign(flattened, flattenFields(fields[i], `${prefix}[${i}]`));
    }
  } else if (isObject(fields)) {
    // use ["key"] syntax for each key
    for (const [key, value] of Object.entries(fields)) {
      // recurse with this name as the prefix
      Object.assign(
        flattened,
        flattenFields(value, prefix === "" ? key : `${prefix}["${key}"]`),
      );
    }
  } else {
    // handle the case of a simple string
    if (prefix === "") {
      return fields;
    }
    // normal value
    flattened[prefix] = fields;
  }

  return flattened;
}

// ----------------------------------------------------------------------
// regions
//
// Generic event delegation. Using data-region allows us to quickly identify where in the
// dom an interaction took place without having to manage individual onClick event
// handlers. A single onClick handler is registered for the entire snapshot console.
//

/**
 * Sets the region of an element, stored in a data-region attribute.
 */
export function setRegion(element: HTMLElement, region: string): void {
  element.dataset["region"] = region;
}

/**
 * Gets the region of the current element, stored in a data-region attribute.
 */
export function getRegion(element: Element | undefined): string | undefined {
  return element instanceof HTMLElement ? element.dataset["region"] : undefined;
}

/**
 * Finds the first parent element with a region and returns the name of that region.
 */
export function findRegion(element: Element | null): string | undefined {
  if (element == null) {
    return;
  }
  const region = getRegion(element);
  if (region != null) {
    return region;
  }
  return findRegion(element.parentElement);
}

/**
 * Finds the first parent element with a region and returns the name of that region.
 */
export function findRegionElement(
  element: Element | null,
): HTMLElement | undefined {
  if (element == null) {
    return;
  }
  const region = getRegion(element);
  if (region != null) {
    return element as HTMLElement;
  }
  return findRegionElement(element.parentElement);
}

/**
 * Finds the first parent element with a region that matches the specified region.
 */
export function findElementWithRegion(
  element: Element | null,
  region: string,
): HTMLElement | undefined {
  if (element == null) {
    return;
  }
  const elementRegion = getRegion(element);
  if (elementRegion === region) {
    return element as HTMLElement;
  }
  return findElementWithRegion(element.parentElement, region);
}
