import { Typography } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import { Maybe } from "graphql/jsutils/Maybe";

export type MinimumAccount = {
  metadata: {
    displayName?: Maybe<string>;
    dateModified?: Maybe<string>;
    id: string;
  };
  userCount: number;
};

interface OrganizationAccountTableProps {
  accounts: MinimumAccount[];
}

export const OrganizationAccountTable: React.FC<
  OrganizationAccountTableProps
> = ({ accounts }) => {
  const columns: GridColDef<MinimumAccount>[] = [
    {
      field: "displayName",
      headerName: "Name",
      valueGetter: (_, row) => row.metadata.displayName,
      renderHeader,
      flex: 1,
    },
    {
      field: "users",
      headerName: "Users",
      renderHeader,
      valueGetter: (_, row: MinimumAccount) => row.userCount,
      width: 200,
    },
  ];
  return (
    <DataGridPro
      columns={columns}
      rows={accounts}
      getRowId={(row: MinimumAccount) => row.metadata.id}
      disableRowSelectionOnClick
      sortingOrder={["asc", "desc"]}
      density="compact"
      slots={{
        footer: () => null,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "displayName", sort: "asc" }],
        },
      }}
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      }}
      disableVirtualization={process.env.NODE_ENV === "test"}
    />
  );
};

function renderHeader(params: GridColumnHeaderParams<MinimumAccount>) {
  return <Typography fontWeight={600}>{params.colDef.headerName}</Typography>;
}
