import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useWizard } from "../Wizard/WizardContext";
import { CheckIcon, ExternalLinkIcon } from "../Icons";
import { parseErrorResponse } from "../../utils/rest/parse-error-response";
import { decodeLicense } from "./license-util";
import { OnboardingWizardValues } from "./types";
import { EULAField } from "./EULAField";

import styles from "./onboarding-steps.module.scss";
import colors from "../../styles/colors";

export const LicenseStep: React.FC = () => {
  const { goToStep, setValues, setFormErrors, formValues, formErrors, step } =
    useWizard<OnboardingWizardValues>();

  function clearError() {
    setFormErrors((prev) => ({ ...prev, license: "" }));
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const decoded = decodeLicense(newValue);
    if (decoded) {
      setValues({
        organizationName: decoded.owner,
        licenseType: decoded.type,
        licenseKey: newValue,
      });
      clearError();
    } else {
      setValues({
        licenseKey: newValue,
        licenseType: null,
        projectName: "",
      });
    }
  }

  async function handleNextClick() {
    // Sanitize the key, remove spaces
    const sanitized = formValues.licenseKey.replace(/\s/g, "");
    setValues({ licenseKey: sanitized });

    const decoded = decodeLicense(sanitized);
    if (decoded === null) {
      setFormErrors((prev) => ({ ...prev, license: "Invalid license key." }));
      return;
    }

    try {
      await updateSystemLicenseKey(sanitized);
    } catch (e) {
      setFormErrors((prev) => ({
        ...prev,
        licenseKey: (e as Error).message,
      }));
      return;
    }

    goToStep(step + 1);
  }

  const nextDisabled = formValues.licenseKey === "" || !formValues.acceptEula;

  return (
    <>
      <div className={styles.stepContainer}>
        <Stack
          justifyContent="center"
          alignItems="start"
          flexGrow={1}
          width="100%"
        >
          <Box width="100%">
            <Stack spacing={2} marginBottom={2}>
              <Stack direction="row" alignItems="end" spacing={2}>
                <Typography variant="h5">Enter your license key</Typography>
                <a
                  className={styles.licenseLink}
                  href="https://www.observiq.com/download"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get a free license
                  <ExternalLinkIcon className={styles.externalLink} />
                </a>
              </Stack>
            </Stack>

            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <TextField
                fullWidth
                multiline
                minRows={14}
                maxRows={14}
                classes={{
                  root: styles.licenseInput,
                }}
                label="License Key"
                value={formValues.licenseKey}
                onChange={handleInputChange}
                error={!!formErrors.licenseKey}
                helperText={formErrors.licenseKey}
              />
            </Box>
          </Box>
          <EULAField
            onChange={(e) => setValues({ acceptEula: e.target.checked })}
            checked={formValues.acceptEula}
          />
          <Stack direction="row" spacing={1} height={"16px"}>
            {formValues.licenseType && (
              <>
                <Typography fontWeight={600}>
                  License Type: {formValues.licenseType}
                </Typography>
                <CheckIcon stroke={colors.malachite} width={15} />
              </>
            )}
          </Stack>
        </Stack>
      </div>

      <Stack direction="row" width="100%" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleNextClick}
          disabled={nextDisabled}
        >
          Next
        </Button>
      </Stack>
    </>
  );
};

/**
 * Sends a rest request to /system/v1/license-key to update the license key.
 *
 * @param licenseKey the license key to update
 * @returns
 */
async function updateSystemLicenseKey(licenseKey: string) {
  const resp = await fetch("/system/v1/license-key", {
    method: "PUT",
    body: JSON.stringify({ licenseKey }),
  });

  if (resp.ok) {
    return;
  }

  const errors = await parseErrorResponse(resp);
  if (errors != null) {
    throw new Error(`Failed to update license key: ${errors.join(", ")}`);
  }

  throw new Error(`Failed to update license key, got status: ${resp.status}.`);
}
