import { Typography, Stack } from "@mui/material";

import { ParamInputProps } from "./ParameterInput";
import styles from "./parameter-input.module.scss";

export const SectionHeaderParam: React.FC<ParamInputProps<boolean>> = ({
  definition,
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      className={styles["telemetry-header-root"]}
    >
      <Typography padding={"5px"} fontSize={18} fontWeight={600}>
        {definition.label}
      </Typography>
    </Stack>
  );
};
