import { Step, Wizard } from "../../components/Wizard";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { BindPlaneLogo } from "../../components/Logos";
import {
  LicenseStep,
  NewOrganizationStep,
  NewProjectStep,
} from "../../components/OnboardingSteps";

import styles from "./app-onboarding.module.scss";
import lights from "../../lights.png";

export type AppOnboardingFormValues = {
  licenseType: string | null;
  license: string;
  acceptEula: boolean;
  accountName: string;
};

const initValues: AppOnboardingFormValues = {
  licenseType: null,
  license: "",
  acceptEula: false,
  accountName: "",
};

const onboardingSteps: Step[] = [
  {
    label: "Enter License",
    description: "Configure your BindPlane OP instance with a license.",
  },
  {
    label: "Create your Organization",
    description: "",
  },
  {
    label: "Start your first project",
    description: "",
  },
];
export const AppOnboardingComponent: React.FC = () => {
  return (
    <div className={styles.container}>
      <img
        src={lights}
        alt="lights.png"
        width={"100%"}
        style={{
          position: "fixed",
          top: "-10rem",
          left: "-0.1rem",
        }}
      />

      <BindPlaneLogo
        width={225}
        height={60}
        style={{ zIndex: 1, marginBottom: 16 }}
      />

      <Wizard<AppOnboardingFormValues>
        steps={onboardingSteps}
        stepComponents={[
          <LicenseStep />,
          <NewOrganizationStep promptEula={false} />,
          <NewProjectStep />,
        ]}
        initialFormValues={initValues}
        paperClass={styles.wizard}
      />
    </div>
  );
};

export const AppOnboarding = withRequireLogin(AppOnboardingComponent);
