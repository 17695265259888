import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthType } from "../../types/auth";
import { getAuthType } from "../../utils/get-auth-type";
import { Auth0LoginPage } from "./Auth0Login";
import { GoogleLoginPage } from "./GoogleLogin";
import { UsernameLogin } from "./UsernameLogin";

/**
 * Props for the LoginPage component.
 */
export type LoginPageProps = {
  /**
   * Whether the default action is to login or sign up
   */
  defaultAction?: "login" | "signup";
};

export const LoginPage: React.FC<LoginPageProps> = ({
  defaultAction = "login",
}) => {
  const authType = getAuthType();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");

  useEffect(() => {
    // Navigate to /agents page if user is logged in.
    if (user != null) {
      navigate("/agents");
    }
  }, [navigate, user]);

  switch (authType) {
    case AuthType.Google:
      return <GoogleLoginPage />;
    case AuthType.Auth0:
      return <Auth0LoginPage defaultAction={defaultAction} />;
    default:
      return <UsernameLogin />;
  }
};
