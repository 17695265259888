import { gql } from "@apollo/client";
import {
  UnlicensedComponentBannerQuery,
  useConfigurationChangesSubscription,
  useUnlicensedComponentBannerQuery,
} from "../../graphql/generated";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { LinkButton } from "../LinkButton";
import { useState } from "react";
import { ExternalLinkIcon } from "../Icons";
import { throttle } from "lodash";

import styles from "./unlicensed-component-banner.module.scss";

gql`
  query UnlicensedComponentBanner {
    appUnlicensedComponentInfo {
      usingUnlicensedComponents
      unlicensedComponents {
        kind
        metadata {
          id
          name
          displayName
          version
        }
      }
    }

    organization {
      metadata {
        id
        name
        version
      }
      licenseType
    }
  }
`;

type Resources =
  UnlicensedComponentBannerQuery["appUnlicensedComponentInfo"]["unlicensedComponents"];

export const UnlicensedComponentBanner: React.FC = () => {
  const { data, refetch } = useUnlicensedComponentBannerQuery();
  const [open, setOpen] = useState(false);

  const throttledRefetch = throttle(refetch, 5000, {
    leading: true,
    trailing: true,
  });

  useConfigurationChangesSubscription({
    onData: () => {
      throttledRefetch();
    },
  });

  if (data == null) {
    return null;
  }

  if (!data.appUnlicensedComponentInfo.usingUnlicensedComponents) {
    return null;
  }

  return (
    <>
      <Alert
        className={styles.banner}
        severity="warning"
        id="unlicensed-component-banner"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={2}
          flexGrow={1}
        >
          <Typography>
            Warning: You are using some components that are restricted for your{" "}
            <strong>{data.organization.licenseType}</strong> license.
          </Typography>
          <LinkButton
            onClick={() => setOpen(true)}
            buttonClassName={styles.button}
          >
            More Info
          </LinkButton>
        </Stack>
      </Alert>
      <UnlicensedComponentsDialog
        open={open}
        onClose={() => setOpen(false)}
        unlicensedComponents={
          data.appUnlicensedComponentInfo.unlicensedComponents
        }
      />
    </>
  );
};

interface UnlicensedComponentsDialogProps extends DialogProps {
  unlicensedComponents: Resources;
}

const UnlicensedComponentsDialog: React.FC<UnlicensedComponentsDialogProps> = ({
  unlicensedComponents,
  ...dialogProps
}) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Unlicensed Components</DialogTitle>
      <DialogContent>
        <Typography>
          You are using components in one or more of your configurations that
          are restricted for your license type. Configurations cannot roll out
          new versions until these components are removed.
        </Typography>
        <Typography marginTop="16px">
          The following components are restricted:
        </Typography>
        <List>
          {unlicensedComponents?.map((c, ix) => (
            <div key={`${c.kind}-${c.metadata.id}`}>
              <ListItem>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Typography>
                    {isResourceType(c)
                      ? c.metadata.displayName
                      : c.metadata.name}{" "}
                    ({c.kind})
                  </Typography>
                  <Button
                    href={linkToConfigQuery(c)}
                    target="_blank"
                    rel="noreferrer"
                    endIcon={<ExternalLinkIcon width="16px" />}
                  >
                    View Configs
                  </Button>
                </Stack>
              </ListItem>
              {ix < unlicensedComponents.length - 1 && <Divider />}
            </div>
          ))}
        </List>
        <Alert severity="info">
          Any Configurations that are using these components will not be able to
          roll out. Contact Support to upgrade your license.
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" href="mailto:support@observiq.com">
          Contact Support
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function isResourceType(component: { kind: string }): boolean {
  return ["SourceType", "ProcessorType", "DestinationType"].includes(
    component.kind,
  );
}

function linkToConfigQuery(c: NonNullable<Resources>[number]) {
  // Lowercase first character to get label
  const kind = c.kind[0].toLowerCase() + c.kind.slice(1);
  return `/configurations?query=${encodeURIComponent(`${kind}:${c.metadata.name}`)}`;
}
