import { Typography } from "@mui/material";
import styles from "./auth0-imitation.module.scss";

interface Auth0CardHeaderProps {
  title?: string;
  description?: string;
}

export const Auth0CardHeader: React.FC<Auth0CardHeaderProps> = ({
  title = "Welcome",
  description,
}) => {
  return (
    <div className={styles.auth0Heading}>
      <img
        className={styles.auth0Logo}
        id="prompt-logo-center"
        src="/icons/sources/bindplane-agent.svg"
        alt="BindPlane OP"
      />
      <Typography fontSize="24px" paddingY="4px" marginBottom="12px">
        {title}
      </Typography>
      {description && <Typography fontSize=".875rem">{description}</Typography>}
    </div>
  );
};
