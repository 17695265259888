import {
  Button,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import { isFunction } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { ResourceNameInput, isValid, useValidationContext } from ".";
import {
  AdditionalInfo,
  Kind,
  ParameterDefinition,
} from "../../graphql/generated";
import { trimVersion } from "../../utils/version-helpers";
import { ActionsSection, TitleSection } from "../DialogComponents";
import { AddToLibraryDialog } from "../Dialogs/AddToLibraryDialog";
import { UnlinkFromLibraryDialog } from "../Dialogs/UnlinkFromLibraryDialog/UnlinkFromLibraryDialog";
import { PauseIcon, PlayIcon } from "../Icons";
import { ViewHeading } from "../ResourceConfigurationEditor/ViewHeading";
import { DialogResource } from "../ResourceDialog";
import { useResourceDialog } from "../ResourceDialog/ResourceDialogContext";
import { ResourceDisplayNameInput } from "./ParameterInput/ResourceDisplayNameInput";
import { ParameterSection } from "./ParameterSection";
import { useResourceFormValues } from "./ResourceFormContext";
import { initFormErrors } from "./init-form-values";
import { groupParameters } from "./utils";

import mixins from "../../styles/mixins.module.scss";
import styles from "./configure-resource-view.module.scss";
interface ConfigureResourceViewProps {
  kind: Kind.Processor | Kind.Extension | Kind.Source | Kind.Destination;
  resourceNameField?: string;
  resourceDisplayNameField?: string;
  resourceType?: string;
  resourceTypeDisplayName: string;
  description: string;
  stability?: string;
  additionalInfo?: AdditionalInfo | null;
  resourceDocLink?: string;
  formValues: { [key: string]: any };
  includeNameField?: boolean;
  includeDisplayNameField?: boolean;
  displayName?: string;
  existingResourceNames?: string[]; // Only used for destinations, should remove after reusable destinations match other reusable resources
  parameterDefinitions: ParameterDefinition[];
  onBack?: () => void;
  onSave?: (formValues: { [key: string]: any }, type?: string) => void;
  saveButtonLabel?: string;
  onDelete?: () => void;
  onTogglePause?: () => void;
  disableSave?: boolean;
  paused?: boolean;
  readOnly?: boolean;
  embedded?: boolean;
  showLibraryBookmark?: boolean;
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (
    values: { [key: string]: any },
    name: string,
    type: string,
    unlinkDisplayName: string,
  ) => void;
  libraryResources?: DialogResource[];
}

export const ConfigureResourceContent: React.FC<ConfigureResourceViewProps> = ({
  kind,
  resourceNameField,
  resourceDisplayNameField,
  resourceType,
  resourceTypeDisplayName,
  description,
  stability,
  additionalInfo,
  resourceDocLink,
  formValues,
  includeNameField,
  includeDisplayNameField,
  displayName,
  existingResourceNames,
  parameterDefinitions,
  onBack,
  onSave,
  saveButtonLabel,
  onDelete,
  disableSave,
  onTogglePause,
  paused,
  readOnly,
  embedded,
  showLibraryBookmark,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  const [resourceInLibrary, setResourceInLibrary] = useState<boolean>();
  const [existingNames, setExistingNames] = useState<string[]>();
  const [existingName, setExistingName] = useState<string>();

  const [addToLibraryDialogOpen, setAddToLibraryDialogOpen] =
    useState<boolean>(false);
  const [unlinkFromLibraryDialogOpen, setUnlinkFromLibraryDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setResourceInLibrary(resourceNameField ? true : false);
    setExistingNames(
      libraryResources?.map((r) => {
        return r.metadata.name;
      }),
    );
    setExistingName(
      libraryResources?.find((r) =>
        r.metadata.name === resourceNameField
          ? trimVersion(resourceNameField)
          : "",
      )?.metadata.name,
    );
  }, [libraryResources, resourceNameField]);

  const { touchAll, setErrors } = useValidationContext();
  const { setFormValues } = useResourceFormValues();
  const { purpose, onClose } = useResourceDialog();

  const groups = useMemo(
    () => groupParameters(parameterDefinitions),
    [parameterDefinitions],
  );

  function handleSubmit() {
    const errors = initFormErrors(
      parameterDefinitions,
      formValues,
      kind,
      includeNameField,
      existingResourceNames,
    );

    if (!isValid(errors)) {
      touchAll();
      setErrors(errors);
      return;
    }

    isFunction(onSave) && onSave(formValues, resourceType);
  }

  function handleAddToLibrary(name: string) {
    setAddToLibraryDialogOpen(false);
    isFunction(onAddToLibrary)
      ? onAddToLibrary(formValues, name)
      : console.error("No function provided for onAddToLibrary");
  }

  function handleUnlinkFromLibrary(
    name: string,
    type: string,
    unlinkDisplayName: string,
  ) {
    setUnlinkFromLibraryDialogOpen(false);
    isFunction(onUnlinkFromLibrary)
      ? onUnlinkFromLibrary(formValues, name, type, unlinkDisplayName)
      : console.error("No function provided for onUnlinkFromLibrary");
  }

  const primaryButton: JSX.Element = (
    <Button
      disabled={disableSave}
      type="submit"
      variant="contained"
      data-testid="resource-form-save"
      onClick={handleSubmit}
    >
      {saveButtonLabel ?? "Save"}
    </Button>
  );

  const backButton: JSX.Element | null = isFunction(onBack) ? (
    <Button variant="outlined" color="secondary" onClick={onBack}>
      Back
    </Button>
  ) : null;

  const deleteButton: JSX.Element | null = isFunction(onDelete) ? (
    <Button variant="outlined" color="error" onClick={onDelete}>
      Delete
    </Button>
  ) : null;

  const togglePauseButton: JSX.Element | null = isFunction(onTogglePause) ? (
    <Button
      size="small"
      variant="outlined"
      color={paused ? "primary" : "secondary"}
      onClick={onTogglePause}
      data-testid="resource-form-toggle-pause"
    >
      {paused ? "Resume" : "Pause"}
    </Button>
  ) : null;

  const title = useMemo(() => {
    const capitalizedResource = kind[0].toUpperCase() + kind.slice(1);
    const action = purpose === "create" ? "Add" : "Edit";
    return resourceNameField
      ? `${action} ${capitalizedResource}: ${trimVersion(resourceNameField)} (${resourceTypeDisplayName})`
      : `${action} ${capitalizedResource}: ${resourceTypeDisplayName}`;
  }, [resourceTypeDisplayName, resourceNameField, kind, purpose]);

  const ActionsContainer = embedded ? ActionsSection : DialogActions;

  const playPauseButtons =
    !readOnly && togglePauseButton ? (
      <ActionsContainer>
        {paused != null &&
          (paused ? (
            <Button size="small" disabled={true} startIcon={<PauseIcon />}>
              Paused
            </Button>
          ) : (
            <Button size="small" disabled={true} startIcon={<PlayIcon />}>
              Running
            </Button>
          ))}
        {!readOnly && togglePauseButton}
      </ActionsContainer>
    ) : null;

  const actionButtons = (
    <ActionsContainer
      sx={{
        marginLeft: "auto",
      }}
    >
      {!readOnly && deleteButton}
      {backButton}
      {!readOnly && primaryButton}
    </ActionsContainer>
  );

  const formHeading = resourceNameField
    ? `${resourceTypeDisplayName}: ${trimVersion(resourceNameField)}`
    : `${resourceTypeDisplayName}`;

  const form = (
    <form data-testid="resource-form" className={styles.form}>
      <Grid container spacing={3} className={mixins["mb-5"]}>
        {includeNameField && (
          <Grid size={{ xs: 6 }}>
            <ResourceNameInput
              readOnly={readOnly}
              kind={kind}
              value={formValues.name}
              onValueChange={(v: string) =>
                setFormValues((prev) => ({ ...prev, name: v }))
              }
              existingNames={existingResourceNames}
            />
          </Grid>
        )}
        <Grid size={{ xs: 12 }}>
          {embedded ? (
            <>
              <ViewHeading
                heading={formHeading}
                subHeading={description}
                stability={stability}
                additionalInfo={additionalInfo}
                resourceDocLink={resourceDocLink}
                showLibraryBookmark={
                  showLibraryBookmark && kind === Kind.Processor
                }
                readOnly={readOnly}
                resourceInLibrary={resourceInLibrary}
                setAddToLibraryDialogOpen={setAddToLibraryDialogOpen}
                setUnlinkFromLibraryDialogOpen={
                  resourceInLibrary ? setUnlinkFromLibraryDialogOpen : undefined
                }
              />
              <AddToLibraryDialog
                onAdd={handleAddToLibrary}
                open={addToLibraryDialogOpen}
                onClose={() => setAddToLibraryDialogOpen(false)}
                kind={kind}
                existingNames={existingNames ?? []}
              />
              <UnlinkFromLibraryDialog
                open={unlinkFromLibraryDialogOpen}
                onClose={() => setUnlinkFromLibraryDialogOpen(false)}
                // If the resource is in the library, the name will be defined
                name={existingName!}
                onUnlink={() =>
                  handleUnlinkFromLibrary(
                    existingName!,
                    resourceType!,
                    resourceDisplayNameField ?? "",
                  )
                }
                kind={kind}
              />
            </>
          ) : (
            <Typography fontWeight={600} fontSize={24}>
              Configure
            </Typography>
          )}
        </Grid>

        {includeDisplayNameField && (
          <Grid size={{ xs: 7 }}>
            <ResourceDisplayNameInput
              readOnly={readOnly}
              value={displayName}
              onValueChange={(v: string) =>
                setFormValues((prev) => ({ ...prev, displayName: v }))
              }
            />
          </Grid>
        )}
        {groups.length === 0 ? (
          <Grid>
            <Typography>No additional configuration needed.</Typography>
          </Grid>
        ) : (
          <>
            {groups.map((g, ix) => (
              <ParameterSection
                key={`param-group-${ix}`}
                group={g}
                readOnly={readOnly}
              />
            ))}
          </>
        )}
      </Grid>
    </form>
  );

  return (
    <Stack className={mixins["flex-grow"]}>
      {!embedded && (
        <>
          <TitleSection
            title={title}
            description={description}
            additionalInfo={additionalInfo}
            onClose={onClose}
            stability={stability}
            resourceDocLink={resourceDocLink}
            showLibraryBookmark={showLibraryBookmark}
            resourceInLibrary={resourceInLibrary}
            setAddToLibraryDialogOpen={setAddToLibraryDialogOpen}
            setUnlinkFromLibraryDialogOpen={
              resourceInLibrary ? setUnlinkFromLibraryDialogOpen : undefined
            }
            readOnly={readOnly}
          />
          <AddToLibraryDialog
            onAdd={handleAddToLibrary}
            open={addToLibraryDialogOpen}
            onClose={() => setAddToLibraryDialogOpen(false)}
            kind={kind}
            existingNames={existingNames ?? []}
          />
          <UnlinkFromLibraryDialog
            open={unlinkFromLibraryDialogOpen}
            onClose={() => setUnlinkFromLibraryDialogOpen(false)}
            // If the resource is in the library, the name will be defined
            name={existingName!}
            onUnlink={() =>
              handleUnlinkFromLibrary(
                existingName!,
                resourceType!,
                resourceDisplayNameField ?? "",
              )
            }
            kind={kind}
          />
        </>
      )}

      {embedded ? (
        <Stack className={mixins["flex-grow"]} overflow="auto">
          {form}
        </Stack>
      ) : (
        <DialogContent dividers className={styles.dialog}>
          {form}
        </DialogContent>
      )}

      {playPauseButtons ? (
        <Stack direction="row">
          {playPauseButtons}
          {actionButtons}
        </Stack>
      ) : (
        actionButtons
      )}
    </Stack>
  );
};

export const ConfigureResourceView = memo(ConfigureResourceContent);
