import { useMemo, useState } from "react";
import { DialogContent, Stack } from "@mui/material";
import { DialogResource, ResourceType } from ".";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import {
  ResourceTypeButton,
  ResourceTypeButtonContainer,
} from "../ResourceTypeButton";
import { useResourceDialog } from "./ResourceDialogContext";
import { TitleSection } from "../DialogComponents";
import { someResourceOfType } from "./utils";
import { Kind } from "../../graphql/generated";
import { ProcessorIcon } from "../Icons";
import { Stability } from "../../types/resources";
import styles from "./resource-dialog.module.scss";
interface SelectViewProps {
  resourceTypes: ResourceType[];
  resources: DialogResource[];
  setSelected: (t: ResourceType) => void;
  setCreateNew: (b: boolean) => void;
  kind: Kind.Source | Kind.Destination | Kind.Processor;
  platform: string;
}

export const SelectView: React.FC<SelectViewProps> = ({
  platform,
  resourceTypes,
  resources,
  setSelected,
  setCreateNew,
  kind,
}) => {
  const [resourceSearchValue, setResourceSearch] = useState("");
  const { onClose } = useResourceDialog();

  const sortedResourceTypes = useMemo(() => {
    const copy = resourceTypes.slice();
    return copy.sort((a, b) =>
      a.metadata
        .displayName!.toLowerCase()
        .localeCompare(b.metadata.displayName!.toLowerCase()),
    );
  }, [resourceTypes]);

  return (
    <>
      <TitleSection title={`Add ${kind}`} onClose={onClose} />

      {/* <ContentSection> */}
      <DialogContent style={{ marginBottom: "10px" }}>
        <Stack
          spacing={1}
          style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}
          flexGrow={1}
        >
          <ResourceTypeButtonContainer
            onSearchChange={(v: string) => setResourceSearch(v)}
          >
            {sortedResourceTypes
              .filter((rt) => filterByPlatform(platform, kind, rt))
              // Filter resource types by the resourceSearchValue
              .filter((rt) =>
                metadataSatisfiesSubstring(rt, resourceSearchValue),
              )
              // map the results to resource buttons
              .map((resourceType) => {
                const matchingResourcesExist = someResourceOfType(
                  resources,
                  resourceType,
                );

                // Either we send the directly to the form if there are no existing resources
                // of that type, or we send them to the Choose View by just setting the selected.
                function onSelect() {
                  setSelected(resourceType);
                  if (!matchingResourcesExist) {
                    setCreateNew(true);
                  }
                }
                return (
                  <ResourceTypeButton
                    key={resourceType.metadata.name}
                    icon={resourceType.metadata.icon!}
                    displayName={resourceType.metadata.displayName!}
                    iconOverrideSVG={
                      kind === Kind.Processor ? (
                        <ProcessorIcon className={styles.overrideIcon} />
                      ) : undefined
                    } // temporary until we add icons to processor metadata or add support for reusable extensions
                    onSelect={onSelect}
                    telemetryTypes={resourceType.spec.telemetryTypes}
                    stability={
                      resourceType.metadata.stability || Stability.UNKNOWN
                    }
                  />
                );
              })}
          </ResourceTypeButtonContainer>
        </Stack>
      </DialogContent>
    </>
  );
};

function filterByPlatform(
  platform: string,
  kind: Kind.Source | Kind.Destination | Kind.Processor,
  resourceType: ResourceType,
) {
  if (kind !== Kind.Source) {
    return true;
  }

  if (platform === "unknown") {
    return true;
  }

  return resourceType.spec.supportedPlatforms.some((p) => p === platform);
}
