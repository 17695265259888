import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { capitalize, isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ConfirmDeleteResourceDialog } from "../../../components/ConfirmDeleteResourceDialog";
import {
  EditUserInfo,
  EditUserRoleDialog,
} from "../../../components/EditUserRoleDialog";
import { EditIcon, MoreVertical, UserXIcon } from "../../../components/Icons";
import { RoleInfo } from "../../../components/RoleInfo";
import { RBACWrapper } from "../../../contexts/RBAC";
import {
  GetAccountPageInfoDocument,
  GetAccountPageInfoQuery,
  Role,
  useRemoveUserMutation,
} from "../../../graphql/generated";

import styles from "./account-page.module.scss";

interface UsersTabProps {
  data?: GetAccountPageInfoQuery;
  currentAccount?: GetAccountPageInfoQuery["accounts"][0];
}

export const UsersTab: React.FC<UsersTabProps> = ({ data, currentAccount }) => {
  const [userOptionsAnchorEl, setUserOptionsAnchorEl] =
    useState<Element | null>(null);
  const [removingUserId, setRemovingUserId] = useState<string | null>(null);
  const [editingUserInfo, setEditingUserInfo] = useState<EditUserInfo | null>(
    null,
  );
  const [editingRole, setEditingRole] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [removeUser] = useRemoveUserMutation({
    onCompleted: (data) => {
      setRemovingUserId(null);
      enqueueSnackbar("User removed", { variant: "success" });

      client.cache.updateQuery<GetAccountPageInfoQuery>(
        {
          query: GetAccountPageInfoDocument,
        },
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            users: data.removeUser,
          };
        },
      );
    },

    onError: (error) => {
      setRemovingUserId(null);
      console.error(error);
      enqueueSnackbar("Failed to remove user", {
        variant: "error",
        key: "remove-user-error",
      });
    },
  });

  function handleTooltipOpen() {
    setTooltipOpen(true);
  }

  function handleTooltipClose() {
    setTooltipOpen(false);
  }

  async function handleRemoveUser() {
    if (removingUserId == null) {
      console.error("missing attribute 'data-userid'");
      return;
    }

    await removeUser({
      variables: {
        input: {
          userId: removingUserId,
        },
      },
    });
  }

  async function handleRemoveUserClick() {
    const userId = userOptionsAnchorEl?.getAttribute("data-userid");
    if (userId == null) {
      console.error("missing data attribute 'userid'");
      return;
    }

    setRemovingUserId(userId);
    setUserOptionsAnchorEl(null);
  }

  async function handleEditRoleClick() {
    const userId = userOptionsAnchorEl?.getAttribute("data-userid");
    if (userId == null) {
      console.error("missing data attribute 'userid'");
      return;
    }

    setUserOptionsAnchorEl(null);

    const user = data?.users.find((u) => u.metadata.id === userId);
    if (user == null) {
      return;
    }

    setEditingUserInfo({
      id: user.metadata.id,
      displayName: user.metadata.displayName ?? "User",
      currentRole: user.role,
    });
    setEditingRole(true);
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Typography className={styles["tab-header"]} marginBottom={2}>
          Users
        </Typography>

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            classes={{ tooltip: styles.tooltip }}
            title={<RoleInfo />}
            PopperProps={{ disablePortal: true }}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button onClick={handleTooltipOpen}>
              What do different roles mean?
            </Button>
          </Tooltip>
        </ClickAwayListener>
      </Stack>

      <Box className={styles.box}>
        <Table size="small">
          <TableHead sx={{ height: 44 }}>
            <TableRow>
              <TableCell width={400}>
                <Typography fontWeight={600}>Login</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Role</Typography>
              </TableCell>
              <TableCell width={10}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.users.map((user) => {
              return (
                <TableRow key={user.metadata.id} sx={{ height: 44 }}>
                  <TableCell>{getLoginId(user)}</TableCell>
                  <TableCell>{capitalize(user.role)}</TableCell>
                  <TableCell>
                    {user.metadata.id !== data.user?.metadata.id && (
                      <RBACWrapper requiredRole={Role.Admin}>
                        <IconButton
                          onClick={(e) =>
                            setUserOptionsAnchorEl(e.currentTarget)
                          }
                          size={"small"}
                          data-userid={user.metadata.id}
                          data-testid={`options-${user.metadata.id}`}
                        >
                          <MoreVertical />
                        </IconButton>
                      </RBACWrapper>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <Menu
            open={Boolean(userOptionsAnchorEl)}
            anchorEl={userOptionsAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{ paper: styles.menu }}
            onClose={() => setUserOptionsAnchorEl(null)}
          >
            <MenuItem onClick={handleRemoveUserClick}>
              <ListItemIcon>
                <UserXIcon width={20} />
              </ListItemIcon>
              <ListItemText>Remove</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleEditRoleClick}>
              <ListItemIcon>
                <EditIcon width={20} />
              </ListItemIcon>
              <ListItemText>Change Role</ListItemText>
            </MenuItem>
          </Menu>
        </Table>
      </Box>

      <ConfirmDeleteResourceDialog
        open={removingUserId != null}
        action="remove"
        onClose={() => setRemovingUserId(null)}
        onDelete={handleRemoveUser}
        onCancel={() => setRemovingUserId(null)}
        fullWidth
        maxWidth="xs"
      >
        <Typography textAlign={"center"}>
          Remove {getLoginIdFromUsers(removingUserId ?? "", data?.users)} from{" "}
          <strong>{currentAccount?.metadata.displayName ?? "account"}</strong>?
        </Typography>
      </ConfirmDeleteResourceDialog>

      <EditUserRoleDialog
        open={editingRole}
        onClose={() => setEditingRole(false)}
        TransitionProps={{
          onExited: () => setEditingUserInfo(null),
        }}
        closeDialog={() => setEditingRole(false)}
        editingUser={editingUserInfo}
      />
    </>
  );
};

function getLoginIdFromUsers(
  id: string,
  users?: GetAccountPageInfoQuery["users"],
): string {
  const user = users?.find((u) => u.metadata.id === id);
  if (user == null) {
    return "User";
  }

  return getLoginId(user);
}

function getLoginId(user: GetAccountPageInfoQuery["users"][0]): string {
  if (!isEmpty(user.spec.email)) {
    return user.spec.email;
  }

  return user.metadata.displayName ?? "";
}
