import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuthType } from "../utils/get-auth-type";
import { AuthType } from "../types/auth";

export const RequireAuth: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  useEffect(() => {
    if (localStorage.getItem("user") == null) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    async function handleLoggedOut() {
      if (localStorage.getItem("user") == null) {
        if (getAuthType() === AuthType.Auth0) {
          await logout({
            openUrl: false,
          });
        }
        navigate("/login");
      }
    }

    window.addEventListener("storage", handleLoggedOut);

    return () => document.removeEventListener("storage", handleLoggedOut);
  });

  useEffect(() => {
    async function verifyLogin() {
      const resp = await fetch("/verify");
      if (resp.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }

    const timeout = setInterval(verifyLogin, 30 * 1000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return <>{children}</>;
};

export function withRequireLogin(FC: React.FC): React.FC {
  return () => (
    <RequireAuth>
      {localStorage.getItem("user") != null ? <FC /> : <Navigate to="/login" />}
    </RequireAuth>
  );
}
