import { useEffect, useState } from "react";

const BASE_HEIGHT_PX = 94;
const BANNER_HEIGHT_PX = 54;

export function useAppBarHeight() {
  const [appBarHeight, setAppBarHeight] = useState(
    document.getElementById("unlicensed-component-banner")
      ? BASE_HEIGHT_PX + BANNER_HEIGHT_PX
      : BASE_HEIGHT_PX,
  );

  useEffect(() => {
    const appBar = document.getElementById("top-nav");

    const observer = new MutationObserver(() => {
      if (document.getElementById("unlicensed-component-banner")) {
        setAppBarHeight(BANNER_HEIGHT_PX + BASE_HEIGHT_PX);
      } else {
        setAppBarHeight(BASE_HEIGHT_PX);
      }
    });

    if (appBar) {
      observer.observe(appBar, { childList: true, subtree: true });
    }

    return () => {
      if (appBar) {
        observer.disconnect();
      }
    };
  }, []);

  return appBarHeight;
}
