import { PipelineType } from "../../graphql/generated";

// Types of fields in a snapshot. Only Logs have Body fields.
// These are intentionally in sync with ConditionInput.ConditionMatch
export enum FieldType {
  Log = "log",
  Metric = "metric",
  DataPoint = "datapoint",
  Span = "span",
  Body = "body",
  Attribute = "attributes",
  Resource = "resource",
  Scope = "scope",
}

export type isFilteredFunc = (
  pipelineType: PipelineType,
  bindplaneID: string,
) => boolean;

export type isModifiedFieldFunc = (
  pipelineType: PipelineType,
  bindplaneID: string,
  fieldType: FieldType,
  fieldKey: string,
  fieldValue: any,
) => boolean;

// message.valueType values
export enum DataPointValueType {
  Empty = 0,
  Int = 1,
  Double = 2,
}

export enum MetricDataType {
  METRIC_DATA_TYPE_NONE = 0,
  METRIC_DATA_TYPE_GAUGE = 1,
  METRIC_DATA_TYPE_SUM = 2,
  METRIC_DATA_TYPE_HISTOGRAM = 3,
  METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM = 4,
  METRIC_DATA_TYPE_SUMMARY = 5,
}

export function dataPointTypeEnumSymbol(type: number): string {
  return MetricDataType[type] ?? MetricDataType[0];
}
