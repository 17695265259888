import { memo } from "react";
import { Log, DataPoint, Span, PipelineType } from "../../../graphql/generated";
import { LogRecordRow } from "./LogRecordRow";
import { MetricsRecordRow } from "./MetricsRecordRow";
import { TraceRecordRow } from "./TraceRecordRow";
import { SnapshotItem, getBindplaneID as getBindPlaneID } from "../utils";

export type AttributesProvider = () => any;

const SnapShotRowComponent: React.FC<{
  filtered: boolean;
  message: SnapshotItem;
  type: PipelineType;
}> = ({ filtered, type, message }) => {
  // provide access to the modified message attributes
  const attributes: AttributesProvider = () => {
    const attrs = Object.assign({}, message.attributes);
    delete attrs["__bindplane_id__"];
    return attrs;
  };

  // extract the bindplaneID from the message attributes
  const bindplaneID = getBindPlaneID(message);

  switch (type) {
    case PipelineType.Logs:
      return (
        <LogRecordRow
          filtered={filtered}
          message={message! as Log}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
    case PipelineType.Metrics:
      return (
        <MetricsRecordRow
          filtered={filtered}
          message={message! as DataPoint}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
    case PipelineType.Traces:
      return (
        <TraceRecordRow
          filtered={filtered}
          message={message! as Span}
          attributes={attributes}
          bindplaneID={bindplaneID}
        />
      );
  }
};

export const SnapshotRow = memo(SnapShotRowComponent);
