import { Stack } from "@mui/material";
import styles from "../snap-shot-console.module.scss";

interface DetailsContainerProps {
  filtered?: boolean;
}

export const DetailsContainer: React.FC<DetailsContainerProps> = ({
  filtered,
  children,
}) => {
  return (
    <div className={styles.fields} data-filtered={filtered}>
      <div>
        <Stack paddingX={4} paddingY={2}>
          {children}
        </Stack>
      </div>
    </div>
  );
};
