import { Stack, Typography } from "@mui/material";
import { EmailIcon, ReturnIcon } from "../../components/Icons";
import { Auth0Card } from "../../components/Auth0Imitation/Auth0Card";
import { Auth0CardHeader } from "../../components/Auth0Imitation/Auth0CardHeader";
import { Auth0CardBody } from "../../components/Auth0Imitation/Auth0CardBody";
import { LinkButton } from "../../components/LinkButton";

import colors from "../../styles/colors";

interface VerifyEmailPageProps {
  onReturnClick: () => void;
}

export const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({
  onReturnClick,
}) => {
  return (
    <Auth0Card data-testid="login-page">
      <Auth0CardHeader description="Verify your email." />

      <Auth0CardBody>
        <Stack alignItems="center" spacing={2}>
          <Typography marginBottom="16px">
            Check your email for a confirmation link.
          </Typography>
          <div
            style={{
              height: 100,
              width: 100,
              border: `2px solid ${colors.darkGray}`,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EmailIcon width="65px" height="64px" />
          </div>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <LinkButton
              onClick={onReturnClick}
              endIcon={<ReturnIcon width="16px" />}
            >
              Return to Log In
            </LinkButton>
          </Stack>
        </Stack>
      </Auth0CardBody>
    </Auth0Card>
  );
};
