import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  ResourceNameInput,
  useValidationContext,
} from "../../ResourceConfigForm";
import { Kind } from "../../../graphql/generated";
import { useState } from "react";

interface AddToLibraryDialogProps extends DialogProps {
  onAdd: (name: string) => void;
  onClose: () => void;
  open: boolean;
  kind:
    | Kind.Configuration
    | Kind.Processor
    | Kind.Extension
    | Kind.Source
    | Kind.Destination;
  existingNames: string[];
}

/**
 * AddToLibrary is used to add an existing resource to the Library and give it a name
 *
 * @param onAdd Function to call when clicking 'Add'
 * @param onClose Function to call when clicking 'Cancel'
 * @param open Whether the dialog is open or not
 * @param kind The kind of resource being added
 * @param existingNames Used to validate name input
 */
export const AddToLibraryDialog: React.FC<AddToLibraryDialogProps> = ({
  onAdd,
  onClose,
  open,
  kind,
  existingNames,
}) => {
  const [name, setName] = useState<string>("");
  const [resourceNameInputErrors, setResourceNameInputErrors] =
    useState<boolean>(true);

  const { setErrors, clearTouched } = useValidationContext();

  function handleClose() {
    setErrors({ name: "" });
    clearTouched();
    onClose();
  }

  function handleAdd() {
    setErrors({ name: "" });
    clearTouched();
    onAdd(name);
  }
  return (
    <Dialog data-testid="add-to-library-dialog" open={open}>
      <DialogTitle>Add {kind} to Library</DialogTitle>
      <DialogContent>
        <Stack marginTop={"5px"}>
          <ResourceNameInput
            kind={kind}
            existingNames={existingNames}
            onValueChange={setName}
            setResourceNameInputErrors={setResourceNameInputErrors}
            autofocus
            isAddToLibraryDialog
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          data-testid={"add-to-library-dialog-cancel-button"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          disabled={resourceNameInputErrors}
          data-testid="add-to-library-dialog-add-button"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
