import { TableRow } from "@mui/material";
import styles from "../snap-shot-console.module.scss";
import { FieldType } from "../types";
import { SnapshotRegion } from "../regions";
import { highlightSearchQuery } from "./utils";
import { CellLabel, CellValue } from "./Cells";
import { useSnapshot } from "../SnapshotContext";
import { isArray, isObject } from "lodash";
import { useSnapshotRow } from "../SnapshotRowContext";

interface FieldRowProps {
  name: string;
  value?: any;
  fieldType: FieldType;
}

export const FieldRow: React.FC<FieldRowProps> = ({
  name,
  value,
  fieldType,
}) => {
  const { searchRegex } = useSnapshot();
  const { isAddedField, isRemovedField } = useSnapshotRow();
  if (value == null) {
    return null;
  }
  return (
    <TableRow
      className={styles["field-row"]}
      key={name}
      data-region={SnapshotRegion.FIELD_ROW}
      data-key={name}
      data-value={typeof value === "object" ? undefined : String(value)}
      data-datatype={typeof value}
      data-fieldtype={fieldType}
      data-added={isAddedField?.(fieldType, name, value)}
      data-removed={isRemovedField?.(fieldType, name, value)}
    >
      <CellLabel>{highlightSearchQuery(name, searchRegex)}</CellLabel>
      {isArray(value) ? (
        <CellValue whiteSpace="pre-wrap">
          {highlightSearchQuery(JSON.stringify(value), searchRegex)}
        </CellValue>
      ) : isObject(value) ? (
        <CellValue whiteSpace="pre-wrap">
          {highlightSearchQuery(JSON.stringify(value, null, 2), searchRegex)}
        </CellValue>
      ) : (
        <CellValue whiteSpace="pre-wrap">
          {highlightSearchQuery(String(value), searchRegex)}
        </CellValue>
      )}
    </TableRow>
  );
};
