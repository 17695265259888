import { isEmpty } from "lodash";
import {
  Maybe,
  Parameter,
  ResourceConfiguration,
} from "../../graphql/generated";
import { trimVersion } from "../version-helpers";

export class BPResourceConfiguration implements ResourceConfiguration {
  id?: Maybe<string> | undefined;
  name?: Maybe<string> | undefined;
  displayName: Maybe<string> | undefined;
  type?: Maybe<string> | undefined;
  parameters?: Maybe<Parameter[]> | undefined;
  processors?: Maybe<ResourceConfiguration[]> | undefined;
  disabled: boolean;
  recommendation?: Maybe<string> | undefined;
  constructor(rc?: ResourceConfiguration) {
    this.id = rc?.id;
    this.name = rc?.name;
    this.displayName = rc?.displayName;
    this.type = rc?.type;
    this.parameters = rc?.parameters;
    this.processors = rc?.processors;
    this.disabled = rc?.disabled ?? false;
    this.recommendation = rc?.recommendation;
  }

  isInline(): boolean {
    return isEmpty(this.name);
  }

  hasConfigurationParameters(): boolean {
    return this.parameters != null && this.parameters.length > 0;
  }

  // setParamsFromMap will set the parameters from Record<string, any>.
  // If the "name" key is specified it will set the name field of the ResourceConfiguration.
  // If the "processors" key is specified it will set the processors value.
  // It will not set undefined or null values to parameters.
  setParamsFromMap(map: Record<string, any>) {
    // Set name field if present
    if (map.name != null && map.name !== "") {
      this.name = map.name;
      delete map.name;
    }

    // Set displayName field if present
    if (map.displayName != null) {
      this.displayName = map.displayName;
      delete map.displayName;
    }

    // Set processors field if present
    if (map.processors != null) {
      this.processors = map.processors;
      delete map.processors;
    }

    // Set the recommendation field if present
    if (map.recommendation != null) {
      this.recommendation = map.recommendation;
      delete map.recommendation;
    }

    // Set the parameters only if their values are not nullish.
    const parameters = Object.entries(map).reduce<Parameter[]>(
      (params, [name, value]) => {
        if (value != null) {
          params.push({ name, value });
        }
        return params;
      },
      [],
    );

    this.parameters = parameters;
  }
}

// isResourceType returns true if the specified ResourceConfiguration is of the given
// type, ignoring the version.
export function isResourceType(
  rc: ResourceConfiguration,
  type: string,
): boolean {
  return trimVersion(rc.type ?? "") === type;
}

// getParameterValue will return the value of the parameter with the given name or a
// defaultValue if it doesn't exist.
export function getParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  defaultValue?: T,
): T | undefined {
  const parameter = rc.parameters?.find((p) => p.name === name);
  return parameter?.value ?? defaultValue;
}

// equalsTelemetryType will return true if the ResourceConfiguration has a single
// telemetry type parameter with the given value.
export function equalsTelemetryType(
  rc: ResourceConfiguration,
  telemetryType: string,
  name: string = "telemetry_types",
): boolean {
  const value = getParameterValue<string[]>(rc, name, []);
  return value?.length === 1 && value[0] === telemetryType;
}

// equalsParameterValue will return true if the parameter with the given name has the
// given value. null and undefined are treated as equal.
export function equalsParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  value: T | undefined | null,
): boolean {
  if (value == null) {
    // handle value == null or value == undefined
    return getParameterValue(rc, name) == null;
  }
  return getParameterValue(rc, name) === value;
}

// editParameterValue will update the value of the parameter with the given name, adding a
// new parameter if it does not exist.
export function editParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  updater: (value?: T) => T,
): T {
  const parameter = rc.parameters?.find((p) => p.name === name);
  if (parameter != null) {
    parameter.value = updater(parameter.value);
    return parameter.value;
  } else {
    if (rc.parameters == null) {
      rc.parameters = [];
    }
    const value = updater(undefined);
    rc.parameters.push({ name, value });
    return value;
  }
}
