export const DISMISSED_RECOMMENDATION_KEY = "dismissedRecommendations";

type resourceName = string;
type testProcessorId = string;
type dismissedRecommendations = Record<resourceName, testProcessorId[]>;

export class DismissedRecommendationStorage {
  private storage: Storage;
  constructor(storage: Storage) {
    this.storage = storage;
  }

  private getStorage(): dismissedRecommendations {
    let dismissed: dismissedRecommendations = {};
    const dismissedStr = this.storage.getItem(DISMISSED_RECOMMENDATION_KEY);
    if (dismissedStr != null) {
      dismissed = JSON.parse(dismissedStr);
    }

    return dismissed;
  }

  /**
   * dismiss will save the test processor ID as dismissed for the given resource.
   * @param resourceName
   * @param testProcessorId
   */
  dismiss(resourceName: resourceName, testProcessorId: testProcessorId) {
    const dismissed = this.getStorage();
    if (!dismissed[resourceName]) {
      dismissed[resourceName] = [];
    }

    dismissed[resourceName].push(testProcessorId);
    this.storage.setItem(
      DISMISSED_RECOMMENDATION_KEY,
      JSON.stringify(dismissed),
    );
  }

  /**
   * getDismissed returns the list of test processor IDs that have been dismissed for the given resource.
   *
   * @param resourceName
   * @returns
   */
  getDismissed(resourceName: resourceName): testProcessorId[] {
    return this.getStorage()[resourceName] || [];
  }
}

/**
 * dismissedRecommendationStorage is a singleton instance of DismissedRecommendationStorage.
 * It keeps track of test processor IDs that have been "dismissed" for a given resource.
 */
export const dismissedRecommendationStorage =
  new DismissedRecommendationStorage(localStorage);
