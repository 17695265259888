import { Card, Chip, Typography } from "@mui/material";
import { differenceInMilliseconds, parseISO } from "date-fns";
import { useMemo, useRef, useState } from "react";
import { PipelineType, Span } from "../../../graphql/generated";
import { SnapshotRegion } from "../regions";
import { flattenFields, getTimestamp } from "../utils";
import { DetailsContainer } from "./DetailsContainer";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { AttributesProvider } from "./SnapShotRow";
import { SummaryTable } from "./SummaryTable";

import { useSnapshot } from "../SnapshotContext";
import styles from "../snap-shot-console.module.scss";
import { FieldType } from "../types";
import { FieldRow } from "./FieldRow";
import { highlightSearchQuery, useWatchForOpen } from "./utils";

interface TraceRecordRowProps {
  filtered?: boolean;
  message: Span;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const TraceRecordRow: React.FC<TraceRecordRowProps> = ({
  filtered,
  message,
  attributes,
  bindplaneID,
}) => {
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Traces),
    [message],
  );

  const diff = useMemo(
    function calcSpan() {
      const [start, end] = [parseISO(message.start), parseISO(message.end)];

      return `${differenceInMilliseconds(end, start)} ms`;
    },
    [message],
  );

  const { searchRegex } = useSnapshot();

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useWatchForOpen(ref, setOpen);

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
      ref={ref}
    >
      <RowSummary
        filtered={filtered}
        bindplaneID={bindplaneID}
        timestamp={timestamp}
        data-region={SnapshotRegion.ROW_SUMMARY}
      >
        <Chip
          size="small"
          label={highlightSearchQuery(message.name, searchRegex)}
        />
        <Typography fontFamily="monospace" fontSize={12}>
          {diff}
        </Typography>
      </RowSummary>

      {open && (
        <DetailsContainer filtered={filtered}>
          <Typography fontWeight={600}>Span</Typography>
          <SummaryTable>
            <FieldRow
              name="start_time"
              value={message.start}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="end_time"
              value={message.end}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="name"
              value={message.name}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="span_id.string"
              value={message.spanID}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="parent_span_id.string"
              value={message.parentSpanID}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="trace_id.string"
              value={message.traceID}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="kind"
              value={message.fields?.kind}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="kind.string"
              value={message.fields?.["kind.string"]}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="status.code"
              value={message.fields?.["status.code"]}
              fieldType={FieldType.Span}
            />
            <FieldRow
              name="status.message"
              value={message.fields?.["status.message"]}
              fieldType={FieldType.Span}
            />
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Attributes
          </Typography>

          <MapValueSummary
            value={flattenFields(attributes())}
            fieldType={FieldType.Attribute}
            emptyMessage="No attribute values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Resource
          </Typography>
          <MapValueSummary
            value={flattenFields(message.resource)}
            fieldType={FieldType.Resource}
            emptyMessage="No resource values"
          />
        </DetailsContainer>
      )}
    </Card>
  );
};
