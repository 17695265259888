import { CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { BindPlaneLogo } from "../../components/Logos";

import styles from "./accept-invitation.module.scss";

export const AcceptInvitationPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const acceptInvitation = async () => {
      const response = await fetch("/v1/accept-invitation", {
        method: "POST",
        body: JSON.stringify({ invitation: searchParams.get("invitation") }),
      });

      switch (response.status) {
        case 200:
          // Success, remove the invitation and reload the page.
          setSearchParams((prev) => {
            prev.delete("invitation");
            return prev;
          });

          window.location.reload();
          break;
        case 401:
          // Unauthorized means the user is not logged in
          // and we can send the to the login page to accept the invitation.
          localStorage.removeItem("user");
          navigate({
            pathname: "/login",
            search: searchParams.toString(),
          });
          return;
        default:
          enqueueSnackbar("Sorry, the invitation is invalid.", {
            variant: "error",
            key: "accept-invitation-error",
          });
          setSearchParams((prev) => {
            prev.delete("invitation");
            return prev;
          });
          navigate("/agents");
          break;
      }
    };

    if (searchParams.get("invitation") == null || user == null) {
      return;
    }

    acceptInvitation();
  }, [enqueueSnackbar, navigate, searchParams, setSearchParams, user]);

  // No invitation and user is not logged in, go to Login Page
  if (searchParams.get("invitation") === null && user == null) {
    return <Navigate to={"/login"} />;
  }

  // User is logged in but no invitation, redirect to /agents
  if (searchParams.get("invitation") == null) {
    return <Navigate to={"/agents"} />;
  }

  // User is not logged in but invitation present, redirect to /login with search params
  if (user == null) {
    return <Navigate to={`/login?${searchParams.toString()}`} />;
  }

  return (
    <Stack
      className={styles.stack}
      height="100%"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <BindPlaneLogo width={200} />
      <CircularProgress size={100} color="secondary" />
    </Stack>
  );
};
