import { CreateOrganizationPayload } from "../../types/rest";

export async function createNewOrganization(
  details: CreateOrganizationPayload,
) {
  return await fetch("/v1/organizations", {
    method: "POST",
    body: JSON.stringify(details),
  });
}
