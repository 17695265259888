import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

type EULAFieldProps = Pick<CheckboxProps, "onChange" | "checked">;

export const EULAField: React.FC<EULAFieldProps> = ({ onChange, checked }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox size="small" checked={checked} onChange={onChange} />
        }
        label={
          <Typography variant="body2">
            I accept the{" "}
            <a
              href="https://observiq.com/legal/eula"
              target="_blank"
              rel="noopener noreferrer"
            >
              End User License Agreement
            </a>
            .
          </Typography>
        }
      />
    </FormGroup>
  );
};
