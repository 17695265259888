import { gql } from "@apollo/client";
import {
  useConfigurationChangesSubscription,
  useConfigurationUnlicensedComponentBannerQuery,
} from "../../../graphql/generated";
import { throttle } from "lodash";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";

gql`
  query ConfigurationUnlicensedComponentBanner($name: String!) {
    configurationUnlicensedComponentsInfo(name: $name) {
      usingUnlicensedComponents
      unlicensedComponents {
        kind
        metadata {
          id
          name
          displayName
          version
        }
      }
    }
  }
`;

interface ConfigurationUnlicensedComponentBannerProps {
  configName: string;
}

export const ConfigurationUnlicensedComponentBanner: React.FC<
  ConfigurationUnlicensedComponentBannerProps
> = ({ configName }) => {
  const { data, refetch } = useConfigurationUnlicensedComponentBannerQuery({
    variables: { name: configName },
  });

  const throttledRefetch = throttle(refetch, 5000, {
    leading: true,
    trailing: true,
  });

  useConfigurationChangesSubscription({
    variables: { query: `name:${configName}` },
    onData: () => throttledRefetch(),
  });

  if (
    data == null ||
    !data.configurationUnlicensedComponentsInfo.usingUnlicensedComponents
  ) {
    return null;
  }

  const resourceNames =
    data.configurationUnlicensedComponentsInfo.unlicensedComponents?.map(
      (r) => `${r.metadata.displayName || r.metadata.name} (${r.kind})`,
    );

  return (
    <Box
      minWidth="800px"
      maxWidth="1600px"
      margin="auto"
      zIndex={1}
      marginBottom="2rem"
    >
      <Alert severity="warning" variant="outlined">
        <AlertTitle>
          <Typography variant="h6">
            This Configuration is using unlicensed components and cannot be
            rolled out.
          </Typography>
        </AlertTitle>
        <Typography>
          <strong>Unlicensed components:</strong> {resourceNames?.join(", ")}.
        </Typography>
      </Alert>
    </Box>
  );
};
