import { gql } from "@apollo/client";
import { Card, CardContent } from "@mui/material";
import { ConfigurationEditor } from "../../../components/ConfigurationEditor";
import { useGetConfigRolloutAgentsQuery } from "../../../graphql/generated";
import { useRefetchOnConfigurationChange } from "../../../hooks/useRefetchOnConfigurationChanges";

import styles from "./configuration-page.module.scss";
import { LiveOrDraft } from ".";

gql`
  query getConfigRolloutAgents($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
      }
      agentCount
    }
  }

  query getRenderedConfigValue($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
      }
      rendered
    }
  }
`;

interface EditorSectionProps {
  configurationName: string;
  isOtel: boolean;
  hideRolloutActions?: boolean;
  historyVersion?: number;
  setHistoryVersion: (n?: number) => void;
  diffDialogOpen: boolean;
  setDiffDialogOpen: (b: boolean) => void;
  view: LiveOrDraft;
  setView: (v: LiveOrDraft) => void;
}

/**
 * EditorSection renders the configuration editor and rollout history.
 *
 * @param configurationName should be the non-versioned name of the configuration.
 * @param isOtel determines whether to display a Topology Graph or a Yaml Editor.
 * @param hideRolloutActions determines whether to hide the rollout actions.
 * @param historyVersion is used to sync RolloutHistory with DiffDialog
 * @param setHistoryVersion is used to sync RolloutHistory with DiffDialog
 * @param diffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @param setDiffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @returns
 */
export const EditorSection: React.FC<EditorSectionProps> = ({
  configurationName,
  isOtel,
  hideRolloutActions,
  historyVersion,
  diffDialogOpen,
  setDiffDialogOpen,
  setHistoryVersion,
  view,
  setView,
}) => {
  const { refetch } = useGetConfigRolloutAgentsQuery({
    variables: { name: configurationName },
    fetchPolicy: "cache-and-network",
  });

  function refetchQueries() {
    refetch();
  }

  useRefetchOnConfigurationChange(configurationName, refetchQueries);

  return (
    <Card className={styles["section-card"]}>
      <CardContent>
        <ConfigurationEditor
          configurationName={configurationName}
          isOtel={isOtel}
          hideRolloutActions={hideRolloutActions}
          historyVersion={historyVersion}
          diffDialogOpen={diffDialogOpen}
          setDiffDialogOpen={setDiffDialogOpen}
          setHistoryVersion={setHistoryVersion}
          view={view}
          setView={setView}
        />
      </CardContent>
    </Card>
  );
};
