import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import { AGENTS_PAGE_QUERY_PARAM } from "../../pages/agents";
import { ExternalLinkIcon, PlusCircleIcon, SendIcon } from "../Icons";
import { ApplyConfigDialog } from "../../pages/configurations/configuration/ApplyConfigDialog";
import { useSnackbar } from "notistack";
import { useGetConfigurationLazyQuery } from "../../graphql/generated";
import { useParams } from "react-router-dom";
import { platformIsContainer } from "../../pages/agents/InstallPage/InstallWizard/utils";
import { EditingState } from "../EditingControlBar/EditingControlBar";

import colors from "../../styles/colors";
import styles from "./rollout-progress.module.scss";

interface RolloutProgressProps {
  configurationName: string;
  totalCount: number;
  errors: number;
  completedCount: number;
  rolloutStatus: number;
  hideActions?: boolean;
  paused: boolean;
  loading?: boolean;
  onPause: () => void;
  onStart: () => void;
  onResume: () => void;
  editingState: EditingState;
  disableStartRollout?: boolean;
}

/**
 * RolloutProgress is a component that displays the progress of a rollout
 * and allows the user to pause or start the rollout.
 *
 * @param configurationName the name of the configuration
 * @param totalCount the total number of agents in the rollout
 * @param errors the number of errored agents in the rollout
 * @param completedCount the number of agents that have completed the rollout
 * @param rolloutStatus used to determine the verbiage of the control button
 * @param hideActions whether to hide the pause/resume/start buttons
 * @param paused whether the rollout is paused, if true,
 * the control button will be "Start Rollout", otherwise it will be "Pause"
 * @param loading whether to display a loading state in the action button
 * @param onPause callback for when the "Pause" button is clicked
 * @param onResume callback for when the "Resume" button is clicked
 * @param editingState Used to sync with EditingControlBar render states
 * @param disableRolloutReason If set will disable the rollout button
 * @returns
 */
export const RolloutProgressBar: React.FC<RolloutProgressProps> = ({
  configurationName,
  totalCount,
  errors,
  completedCount,
  rolloutStatus,
  hideActions,
  loading,
  onPause,
  onStart,
  onResume,
  editingState,
  disableStartRollout,
}) => {
  const value = (completedCount / totalCount) * 100;

  const [showApplyDialog, setShowApply] = useState(false);

  const label = useMemo(() => {
    if (editingState === "discarding") {
      return "Discarding";
    }
    switch (rolloutStatus) {
      case 1: // started
        return "Rollout in Progress";
      case 2: // paused
      case 3: // errored
        return "Rollout Paused";
      case 4: // stable
        return "Rollout Complete";
      default:
        return "Rollout Pending";
    }
  }, [rolloutStatus, editingState]);

  const agentsWithErrorsUrl = useMemo(() => {
    const query = `status:error configuration:${configurationName}`;
    const params = new URLSearchParams();
    params.set(AGENTS_PAGE_QUERY_PARAM, query);

    return `/agents?${params.toString()}`;
  }, [configurationName]);

  const { enqueueSnackbar } = useSnackbar();

  function toast(msg: string, variant: "error" | "success") {
    enqueueSnackbar(msg, { variant: variant, autoHideDuration: 3000 });
  }

  function onApplySuccess() {
    toast("Saved configuration!", "success");
    setShowApply(false);
  }

  const { name } = useParams();

  const [fetchConfig, { data }] = useGetConfigurationLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const actionButton = useMemo(() => {
    if (hideActions) {
      return null;
    }

    if (totalCount === 0) {
      return (
        !platformIsContainer(
          data?.configuration?.metadata?.labels?.platform,
        ) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowApply(true)}
            startIcon={<PlusCircleIcon />}
            data-testid="config-edit-bar-add-agents-button"
          >
            Add Agents
          </Button>
        )
      );
    }

    switch (rolloutStatus) {
      case 0: // pending
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={onStart}
            startIcon={<SendIcon />}
            data-testid="config-edit-bar-start-rollout-button"
            disabled={!!disableStartRollout}
          >
            Start Rollout
          </Button>
        );
      case 1: // started
        return (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onPause}
            loading={loading}
            data-testid="config-edit-bar-pause-button"
          >
            Pause
          </LoadingButton>
        );
      case 2: // paused
      case 3: // errored
        return (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onResume}
            loading={loading}
            data-testid="config-edit-bar-resume-button"
          >
            Resume
          </LoadingButton>
        );
    }
  }, [
    hideActions,
    totalCount,
    rolloutStatus,
    data?.configuration?.metadata?.labels?.platform,
    onStart,
    disableStartRollout,
    onPause,
    loading,
    onResume,
  ]);

  useEffect(() => {
    if (name) {
      fetchConfig({
        variables: {
          name: `${name}`,
        },
      });
    }
  }, [fetchConfig, name]);

  return (
    <Box flexGrow={1}>
      <Stack direction="row" width="100%" alignItems={"center"} spacing={2}>
        {actionButton && (
          <Box className={styles["control-box"]}>{actionButton}</Box>
        )}
        {data?.configuration && (
          <ApplyConfigDialog
            configuration={data.configuration}
            maxWidth="lg"
            fullWidth
            open={showApplyDialog}
            onError={() => toast("Failed to apply configuration.", "error")}
            onSuccess={onApplySuccess}
            onClose={() => setShowApply(false)}
            onCancel={() => setShowApply(false)}
          />
        )}
        <Box
          flexGrow={1}
          className={
            totalCount === 0 ? styles["rollout-progress-disabled"] : ""
          }
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            marginBottom={"1px"}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Typography fontSize={18} fontWeight={300}>
                {label}
              </Typography>
              {errors > 0 && editingState !== "discarding" && (
                <Link
                  href={agentsWithErrorsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  style={{ color: colors.muiErrorDark }}
                  data-testid="rollout-progress-agents-error-link"
                >
                  <Button
                    color="error"
                    endIcon={<ExternalLinkIcon width={15} />}
                    sx={{
                      padding: "0",
                      "& .MuiButton-endIcon": {
                        marginRight: "0",
                      },
                    }}
                  >
                    <Typography color="error" fontSize={14}>
                      {errors} error{errors > 1 ? "s" : ""}
                    </Typography>
                  </Button>
                </Link>
              )}
            </Stack>

            <Typography fontSize={16} fontWeight={600}>
              {completedCount}/{totalCount}
            </Typography>
          </Stack>

          <LinearProgress
            variant="determinate"
            value={
              totalCount === 0 || editingState === "discarding" ? 0 : value
            }
          />
        </Box>
      </Stack>
    </Box>
  );
};
