import {
  DataGridPro,
  DataGridProProps,
  GridDensity,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { memo, useEffect, useState } from "react";
import { isFunction } from "lodash";
import { Stack } from "@mui/material";
import { AgentMetrics, AgentsTableField, TableAgent } from "./types";
import { makeAgentsTableColumns } from "./render-fns";

export interface EEAgentsDataGridProps {
  onAgentsSelected?: (agentIds: GridRowSelectionModel) => void;
  isRowSelectable?: (params: GridRowParams<TableAgent>) => boolean;
  clearSelectionModelFnRef?: React.MutableRefObject<(() => void) | null>;
  density?: GridDensity;
  loading: boolean;
  minHeight?: string;
  maxHeight?: string;
  agents?: TableAgent[];
  agentMetrics?: AgentMetrics;
  columnFields?: AgentsTableField[];
  allowSelection: boolean;

  paginationModel: GridPaginationModel;
  onPaginationModelChange: DataGridProProps["onPaginationModelChange"];

  sortModel: GridSortModel;
  onSortModelChange: DataGridProProps["onSortModelChange"];

  rowCount: number;

  // autoHeight is used in testing to enable the autoHeight prop.
  autoHeight?: boolean;

  // When passed, the noRowsOverlay will override the default
  // no rows overlay.
  noRowsOverlay?: React.FC<{}>;

  // The call back to use when the agent name is clicked
  // Defaults to a link to the agent details page.
  onAgentClick?: (agentID: string, agentName: string) => void;
}

const defaultFields = [
  AgentsTableField.NAME,
  AgentsTableField.STATUS,
  AgentsTableField.VERSION,
  AgentsTableField.CONFIGURATION,
  AgentsTableField.LOGS,
  AgentsTableField.METRICS,
  AgentsTableField.TRACES,
  AgentsTableField.OPERATING_SYSTEM,
  AgentsTableField.LABELS,
];

export const EEAgentsDataGrid: React.FC<EEAgentsDataGridProps> = memo(
  ({
    clearSelectionModelFnRef,
    onAgentsSelected,
    isRowSelectable,
    minHeight,
    maxHeight,
    loading,
    agents = [],
    agentMetrics = [],
    columnFields = defaultFields,
    allowSelection,
    onPaginationModelChange,
    paginationModel,
    sortModel,
    onSortModelChange,
    rowCount,
    autoHeight,
    noRowsOverlay,
    density = "standard",
    onAgentClick,
  }) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
      [],
    );

    useEffect(() => {
      if (clearSelectionModelFnRef == null) {
        return;
      }
      clearSelectionModelFnRef.current = function () {
        setSelectionModel([]);
      };
    }, [setSelectionModel, clearSelectionModelFnRef]);

    const columns = makeAgentsTableColumns(
      columnFields,
      agentMetrics,
      onAgentClick,
    );
    // Disable sorting on columns that are not sortable on the backend.
    columns.forEach((column) => {
      if (!sortableFields.includes(column.field as AgentsTableField)) {
        column.sortable = false;
      }
    });

    function handleSelect(s: GridRowSelectionModel) {
      setSelectionModel(s);

      isFunction(onAgentsSelected) && onAgentsSelected(s);
    }

    return (
      <DataGridPro
        checkboxSelection={isFunction(onAgentsSelected) && allowSelection}
        isRowSelectable={isRowSelectable}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selectionModel}
        density={density}
        slots={{
          noRowsOverlay: noRowsOverlay ?? DefaultNoRowsOverlay,
        }}
        style={{ minHeight, maxHeight }}
        loading={loading}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        disableRowSelectionOnClick
        columns={columns}
        rows={agents}
        paginationMode="server"
        paginationModel={paginationModel}
        pagination
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount}
        data-testid="agents-table"
        autoHeight={autoHeight}
        sortingOrder={["asc", "desc"]}
      />
    );
  },
);

const DefaultNoRowsOverlay: React.FC = () => {
  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      No Agents
    </Stack>
  );
};

// sortableFields represent the fields that can be sorted on the backend
// and should be updated when more fields are supported.
const sortableFields: AgentsTableField[] = [
  AgentsTableField.NAME,
  AgentsTableField.STATUS,
  AgentsTableField.OPERATING_SYSTEM,
  AgentsTableField.VERSION,
  AgentsTableField.CONFIGURATION,
  AgentsTableField.CONFIGURATION_VERSION,
  AgentsTableField.LOGS,
  AgentsTableField.METRICS,
  AgentsTableField.TRACES,
  AgentsTableField.LABELS,
];
