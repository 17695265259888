import { ComponentProps, useState } from "react";
import { Chip, Stack } from "@mui/material";
import { AgentStatus } from "../../types/agents";
import { format } from "date-fns";
import { timeAgoInWords } from "../../utils/time";
import { LabelEditor } from "../LabelEditor";
import { LinkButton } from "../LinkButton";
import { Maybe } from "graphql/jsutils/Maybe";
import { AGENT_LABEL_INSTALL_ID } from "../../pages/agents/InstallPage/InstallWizard/utils";

interface LabelBlockProps {
  agentId: string;
  labels: Maybe<Record<string, string>>;
}

export const LabelBlock: React.FC<LabelBlockProps> = ({ labels, agentId }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  function handleAddLabelClick(event: React.MouseEvent<HTMLButtonElement>) {
    setPopoverAnchorEl(event.currentTarget);
  }

  if (labels == null)
    return (
      <>
        <LinkButton onClick={handleAddLabelClick}>edit</LinkButton>
      </>
    );

  return (
    <>
      <LabelEditor
        agentIds={[agentId]}
        popoverProps={{
          open: Boolean(popoverAnchorEl),
          anchorEl: popoverAnchorEl,
          onClose: () => setPopoverAnchorEl(null),
        }}
        onSave={async () => {}}
        onClose={() => setPopoverAnchorEl(null)}
        existingLabels={Object.entries(labels)}
      />
      <Stack direction="row" flexWrap="wrap" alignItems="center">
        {Object.entries(labels).map(([key, value]) => {
          if (!isCustomLabelKey(key)) return null;
          if (key === AGENT_LABEL_INSTALL_ID) return null;
          return (
            <Chip
              key={key}
              size="small"
              label={`${key}: ${value}`}
              style={{
                marginRight: "4px",
                marginBottom: "4px",
              }}
            />
          );
        })}
        <LinkButton onClick={handleAddLabelClick}>edit</LinkButton>
      </Stack>
    </>
  );
};

export function renderAgentStatus(
  status: AgentStatus | undefined,
): JSX.Element {
  let statusText: string;
  let color: ComponentProps<typeof Chip>["color"] = "default";

  switch (status) {
    case AgentStatus.DISCONNECTED:
      statusText = "Disconnected";
      break;
    case AgentStatus.CONNECTED:
      statusText = "Connected";
      color = "success";
      break;
    case AgentStatus.ERROR:
      statusText = "Errored";
      color = "error";
      break;
    // Component failed is deprecated.
    case AgentStatus.COMPONENT_FAILED:
      statusText = "Component Failed";
      break;
    case AgentStatus.DELETED:
      statusText = "Deleted";
      break;
    case AgentStatus.CONFIGURING:
      statusText = "Configuring";
      break;
    case AgentStatus.UPGRADING:
      statusText = "Upgrading";
      color = "warning";
      break;
    default:
      statusText = "";
      break;
  }

  return <Chip size="small" color={color} label={statusText} />;
}

export function renderAgentDate(date: string | undefined): JSX.Element {
  if (date == null) {
    return <>-</>;
  }

  return <>{format(new Date(date), "MMM dd yyyy HH:mm")}</>;
}

export function renderAgentReported(date: string | undefined): JSX.Element {
  if (date == null) {
    return <>-</>;
  }

  return <>{timeAgoInWords(new Date(date))}</>;
}

export function getCustomLabels(labels: Record<string, string>) {
  const customLabels: Record<string, string> = {};
  for (const [k, v] of Object.entries(labels)) {
    if (isCustomLabelKey(k)) {
      customLabels[k] = v;
    }
  }

  return customLabels;
}

export function isCustomLabelKey(name: string): boolean {
  return !name.startsWith("bindplane/") && !name.startsWith("attributes/");
}
