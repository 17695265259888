import {
  MenuItem,
  Typography,
  Divider,
  FormControl,
  Select,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo } from "react";
import {
  Maybe,
  useChangeAccountMutation,
  useMultiAccountEnabledQuery,
} from "../../graphql/generated";
import { NewAccountDialog } from "../NewAccountDialog";
import { CheckIcon, PlusCircleIcon } from "../Icons";
import { getAccountsEnabled } from "../EESettingsMenu/utils";
import { gql } from "@apollo/client";

import styles from "./account-switcher.module.scss";
import colors from "../../styles/colors";

gql`
  query multiAccountEnabled {
    featureGate(id: "multiaccount")
  }
`;

export type Account = {
  metadata: {
    id: string;
    name: string;
    displayName?: Maybe<string>;
  };
};

interface AccountSwitcherProps {
  accounts?: Account[];
  activeAccountId?: string;
  orgAdmin?: boolean;
}

export const AccountSwitcher: React.FC<AccountSwitcherProps> = ({
  accounts,
  activeAccountId,
  orgAdmin = false,
}) => {
  const [newProjectDialogOpen, setCreateNew] = useState(false);
  const [multiAccountEnabled, setMultiAccountEnabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [changeAccount, { error }] = useChangeAccountMutation();
  const accountsEnabled = getAccountsEnabled();

  useMultiAccountEnabledQuery({
    onCompleted: (data) => {
      setMultiAccountEnabled(data.featureGate);
    },
  });

  useEffect(() => {
    if (error != null) {
      console.error(error);
      enqueueSnackbar("Error changing project", {
        variant: "error",
        key: "change-project-error",
      });
    }
  }, [error, enqueueSnackbar]);

  function handleNewAccountSuccess() {
    setCreateNew(false);
    window.location.pathname = "/overview";
  }

  async function handleChangeAccount(accountId: string) {
    await changeAccount({
      variables: {
        input: {
          accountId,
        },
      },
    });
    window.location.pathname = "/overview";
  }

  const { current, other } = useMemo(
    () => sortAccountsList(accounts || [], activeAccountId || ""),
    [accounts, activeAccountId],
  );

  if (current == null) {
    return null;
  }

  return (
    <>
      <FormControl fullWidth sx={{ paddingX: "12px" }}>
        <Select
          MenuProps={{
            sx: {
              "&& .MuiMenuItem-root": {
                backgroundColor: "white",
              },
              "&& .MuiMenuItem-root:hover": {
                backgroundColor: "rgba(0, 115, 229, 0.08)",
              },
            },
          }}
          id="account-switcher-select"
          value={current.metadata.id}
          size="small"
          variant="standard"
          renderValue={(value) =>
            accounts?.find((act) => act.metadata.id === value)?.metadata
              .displayName
          }
          defaultValue={current.metadata.id}
          data-testid="account-switcher-select"
        >
          {/** This is a hack to make the options appear as the first child of the select.
           * It avoids a warning from MUI when the first child is not a MenuItem.
           */}
          {accounts?.map((act) => (
            <MenuItem
              key={act.metadata.id}
              value={act.metadata.id}
              style={{ display: "none" }}
              aria-hidden
            >
              {act.metadata.displayName}
            </MenuItem>
          ))}
          <Stack maxHeight="300px" overflow="auto">
            {current && (
              <MenuItem
                disabled
                selected
                classes={{ root: styles.current }}
                value={current.metadata.id}
                data-testid={`current-account-${current.metadata.id}`}
              >
                <Typography className={styles["current-typo"]}>
                  {current.metadata.displayName}
                </Typography>
                <CheckIcon className={styles.check} />
              </MenuItem>
            )}
            {other?.map((act) => (
              <MenuItem
                key={`switcher-${act.metadata.id}`}
                onClick={() => handleChangeAccount(act.metadata.id)}
                value={current.metadata.id}
                data-testid={`account-${act.metadata.displayName}`}
              >
                <Typography>{act.metadata.displayName}</Typography>
              </MenuItem>
            ))}
          </Stack>

          {orgAdmin && multiAccountEnabled && (
            <div>
              <Divider />

              <span>
                <MenuItem
                  onClick={() => setCreateNew(true)}
                  disabled={!accountsEnabled}
                  data-testid="new-account-menu-item"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    marginTop="4px"
                    width="100%"
                    spacing={2}
                  >
                    <PlusCircleIcon
                      width="20px"
                      color={colors.pixelPointBlue}
                    />
                    <Typography color={colors.pixelPointBlue}>
                      New Project
                    </Typography>
                  </Stack>
                </MenuItem>
              </span>
            </div>
          )}
        </Select>
      </FormControl>

      <NewAccountDialog
        open={newProjectDialogOpen}
        onClose={() => setCreateNew(false)}
        onSuccess={handleNewAccountSuccess}
      />
    </>
  );
};

function sortAccountsList(
  accounts: Account[],
  currentAccountId: string,
): {
  current: Account | undefined;
  other: Account[];
} {
  const current = accounts.find((act) => act.metadata.id === currentAccountId);
  const other = accounts.filter((act) => act.metadata.id !== currentAccountId);

  // sort other by displayName
  other.sort((a, b) => {
    const aDisplayName = a.metadata.displayName ?? "";
    const bDisplayName = b.metadata.displayName ?? "";
    return aDisplayName.toLowerCase().localeCompare(bDisplayName.toLowerCase());
  });

  return { current, other };
}
