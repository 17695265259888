import { createContext, useContext } from "react";
import { PipelineType } from "../../graphql/generated";
import { FieldType, isModifiedFieldFunc } from "./types";

export interface SnapshotRowContextValue {
  isAddedField?: (
    fieldType: FieldType,
    fieldKey: string,
    fieldValue: any,
  ) => boolean;
  isRemovedField?: (
    fieldType: FieldType,
    fieldKey: string,
    fieldValue: any,
  ) => boolean;
}

const defaultValue: SnapshotRowContextValue = {
  isAddedField: () => false,
  isRemovedField: () => false,
};

export const SnapshotRowContext = createContext(defaultValue);

export interface SnapshotRowProviderProps {
  pipelineType: PipelineType;
  bindplaneID: string;
  isAddedField?: isModifiedFieldFunc;
  isRemovedField?: isModifiedFieldFunc;
}

export const SnapshotRowContextProvider: React.FC<SnapshotRowProviderProps> = ({
  pipelineType,
  bindplaneID,
  children,
  isAddedField,
  isRemovedField,
}) => {
  const isAdded = isAddedField
    ? (fieldType: FieldType, fieldKey: string, fieldValue: any) => {
        return isAddedField(
          pipelineType,
          bindplaneID,
          fieldType,
          fieldKey,
          fieldValue,
        );
      }
    : undefined;

  const isRemoved = isRemovedField
    ? (fieldType: FieldType, fieldKey: string, fieldValue: any) => {
        return isRemovedField(
          pipelineType,
          bindplaneID,
          fieldType,
          fieldKey,
          fieldValue,
        );
      }
    : undefined;

  return (
    <SnapshotRowContext.Provider
      value={{ isAddedField: isAdded, isRemovedField: isRemoved }}
    >
      {children}
    </SnapshotRowContext.Provider>
  );
};

export function useSnapshotRow(): SnapshotRowContextValue {
  return useContext(SnapshotRowContext);
}
