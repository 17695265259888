import { Chip, ChipOwnProps } from "@mui/material";

interface StabilityChipProps {
  color: ChipOwnProps["color"];
  label: string;
  style?: React.CSSProperties;
}

/**
 * StabilityChip displays the stability in a round, styled chip.
 */
export const StabilityChip: React.FC<StabilityChipProps> = ({
  color,
  label,
  style,
}) => {
  return (
    <Chip
      color={color}
      label={label}
      style={style}
      size="small"
      variant="filled"
    />
  );
};
