import { TableRow, Typography } from "@mui/material";
import { isEmpty, isNil, isObject } from "lodash";
import { useSnapshot } from "../SnapshotContext";
import { FieldType } from "../types";
import { CellValue } from "./Cells";
import { FieldRow } from "./FieldRow";
import { SummaryTable } from "./SummaryTable";
import { highlightSearchQuery } from "./utils";

interface MapValueSummaryProps {
  value: Record<string, any> | undefined;
  fieldType: FieldType;
  // The message to display when value is empty or undefined
  emptyMessage: string;
}

export const MapValueSummary: React.FC<MapValueSummaryProps> = ({
  value,
  fieldType,
  emptyMessage,
}) => {
  const { searchRegex } = useSnapshot();

  return isNil(value) || isEmpty(value) ? (
    <Typography
      marginLeft={2}
      marginTop={1}
      fontWeight={300}
      fontSize={14}
      component="span"
      color="gray"
    >
      {emptyMessage}
    </Typography>
  ) : (
    <SummaryTable>
      {isObject(value) ? (
        Object.entries(value).map(([k, v], ix) => {
          return <FieldRow key={k} name={k} value={v} fieldType={fieldType} />;
        })
      ) : (
        <TableRow>
          <CellValue whiteSpace="pre-wrap">
            {highlightSearchQuery(String(value), searchRegex)}
          </CellValue>
        </TableRow>
      )}
    </SummaryTable>
  );
};
