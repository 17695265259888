import React from "react";
import {
  AutocompleteRenderInputParams,
  Paper,
  Button,
  Menu,
  MenuItem,
  Divider,
  InputBase,
  Box,
} from "@mui/material";
import { KeyboardArrowDownIcon } from "../Icons";
import { Suggestion } from "../../graphql/generated";
import { classes } from "../../utils/styles";

import styles from "./search-bar.module.scss";
import mixins from "../../styles/mixins.module.scss";

export const SearchInput: React.FC<
  AutocompleteRenderInputParams & {
    inputValue: string;
    popperElRef: React.MutableRefObject<HTMLSpanElement | null>;
    // The suggestions to display in the filter drop down.
    filterOptions?: Suggestion[];
    onFilterClick: (query: string) => void;
    placeholder?: string;
    autofocus?: boolean;
    error: boolean;
    tall?: boolean;
    endAdornment?: JSX.Element;
  }
> = ({
  inputValue,
  popperElRef,
  onFilterClick,
  filterOptions,
  placeholder,
  error,
  autofocus,
  tall,
  endAdornment,
  ...params
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterOptionClick = (query: string) => {
    onFilterClick(query);
    setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Paper
      className={classes([
        tall ? styles["taller-input"] : styles.input,
        error ? styles.error : undefined,
      ])}
      variant={"outlined"}
      ref={params.InputProps.ref}
    >
      {filterOptions && (
        <>
          <Button
            variant="text"
            color="inherit"
            onClick={handleFilterMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Filters
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {filterOptions.map((o) => (
              <MenuItem
                key={o.label}
                onClick={() => handleFilterOptionClick(o.query)}
              >
                {o.label}
              </MenuItem>
            ))}
          </Menu>
          <Divider
            flexItem
            classes={{ root: mixins["mr-1"] }}
            orientation="vertical"
          />
        </>
      )}
      {/* This span is used to place the autocomplete popover */}
      <span ref={inputValue === "" ? popperElRef : undefined} />
      <InputBase
        aria-label={placeholder ?? "Filter by field or label"}
        classes={{ root: mixins["ml-2"] }}
        placeholder={placeholder ?? "Filter by field or label"}
        style={{
          width: getInputWidth(inputValue),
          fontFamily: "monospace",
          fontSize: 12,
        }}
        autoFocus={autofocus}
        inputProps={params.inputProps}
        data-testid="search-input"
      />
      {/* This span is used to place the autocomplete popover */}
      <span ref={inputValue !== "" ? popperElRef : undefined} />
      <Box flexGrow={1} />
      {endAdornment}
    </Paper>
  );
};

function getInputWidth(value: string): number {
  const charWidth = 7.5;
  if (value === "") return 200;
  return Math.ceil(value.length * charWidth);
}
