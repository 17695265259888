import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ParamInputProps } from "./ParameterInput";
import { memo } from "react";
import { isArray, isFunction } from "lodash";
import { useValidationContext } from "../ValidationContext";
import { validateStringsField } from "../validation-functions";
import styles from "./parameter-input.module.scss";

const OrderedListInputComponent: React.FC<ParamInputProps<string[]>> = ({
  definition,
  value,
  onValueChange,
}) => {
  const { errors, setError } = useValidationContext();

  function handleOnChange(v: any) {
    const changedValue = v as string[];
    if (isFunction(onValueChange) && isArray(v)) {
      onValueChange(v);
    }
    setError(
      definition.name,
      validateStringsField(changedValue, definition.required),
    );
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={definition.validValues!}
        value={value}
        multiple
        onChange={(_e, v: any) => handleOnChange(v)}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              size="small"
              label={option}
              {...getTagProps({ index })}
              classes={{ root: styles.chip, label: styles["chip-label"] }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size={"small"}
            label={definition.label}
            required={definition.required}
            helperText={
              <>
                <Typography
                  fontSize={"0.75rem"}
                  component="span"
                  whiteSpace="pre-wrap"
                >
                  {definition.description}
                </Typography>

                {definition.documentation && (
                  <Stack component={"span"}>
                    {definition.documentation?.map((d) => (
                      <a href={d.url} key={d.url}>
                        {d.text}
                      </a>
                    ))}
                  </Stack>
                )}
              </>
            }
            id={definition.name}
          />
        )}
      />
      {errors[definition.name] && (
        <FormHelperText error={true}>{errors[definition.name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export const OrderedListInput = memo(OrderedListInputComponent);
