import { ButtonBase } from "@mui/material";
import { classes } from "../../utils/styles";
import styles from "./link-button.module.scss";

interface LinkButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  endIcon?: JSX.Element;
  buttonClassName?: string;
  dataTestID?: string;
}

/**
 * LinkButton is a button with no background and is underlined
 *
 * @param onClick() is the onClick() function this button calls
 * @param buttonClassName is an optional string containing any additional classes being passed to this button
 * @param dataTestID is an optional data-testid
 */
export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      className={classes([styles["link-button"], props.buttonClassName])}
      data-testid={props.dataTestID}
    >
      {props.children}
      {props.endIcon && (
        <span style={{ marginLeft: "4px", marginTop: "4px" }}>
          {props.endIcon}
        </span>
      )}
    </ButtonBase>
  );
};
