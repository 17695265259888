import { Stack, Typography } from "@mui/material";
import { formatLogDate } from "../utils";
import { Toggle } from "./Toggle";
import { SnapshotRegion } from "../regions";

import styles from "../snap-shot-console.module.scss";

interface RowSummaryProps {
  filtered?: boolean;
  bindplaneID: string;
  timestamp: string;
}

export const RowSummary: React.FC<RowSummaryProps> = ({
  filtered,
  bindplaneID,
  timestamp,
  children,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      width={"100%"}
      className={styles.row}
      data-filtered={filtered}
      title={filtered ? "Filtered by Processors" : undefined}
    >
      <div
        className={styles.ch}
        data-region={SnapshotRegion.EXPANDER}
        data-bindplane-id={bindplaneID}
      >
        <Toggle bindplaneID={bindplaneID} />
      </div>

      <div className={styles.dt}>
        <Typography fontFamily="monospace" fontSize={12} marginLeft={0.2}>
          {formatLogDate(new Date(timestamp))}
        </Typography>
      </div>

      <div className={styles.summary}>
        <Stack direction="row" spacing={2} alignItems="center">
          {children}
        </Stack>
      </div>
    </Stack>
  );
};
